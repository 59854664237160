import { Button, Typography, View, theme } from '@ornikar/kitt-universal';
import { useState } from 'react';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest/source/except';
import { RadioBlockGroup } from '../../RadioBlockGroup';
import type { Choices } from '../../RadioBlockGroup';
import type { ScreenTemplateWithoutFormProps } from '../ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { ScreenTemplateWithoutForm } from '../ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';

export interface RadioScreenTemplateProps<ValueType>
  extends Except<ScreenTemplateWithoutFormProps, 'children' | 'submitButtonComponent'> {
  id: string;
  fieldName: string;
  onChange: (value?: ValueType) => void | Promise<void>;
  onPressContinue: () => void;
  children?: ReactNode;
  choices: Choices<ValueType>[];
  defaultValue?: ValueType;
  errorMessage?: ReactNode;
}

export function RadioScreenTemplateWithoutForm<ValueType>({
  id,
  fieldName,
  children,
  choices,
  defaultValue,
  onPressContinue,
  errorMessage,
  ...props
}: RadioScreenTemplateProps<ValueType>): ReactNode {
  const [value, setValue] = useState(defaultValue);

  const onChange = (selectedValue?: ValueType): void => {
    setValue(selectedValue);
    props.onChange(selectedValue);
  };

  return (
    <ScreenTemplateWithoutForm
      {...props}
      submitButtonComponent={
        value === undefined ? null : (
          <Button type="primary" testID="quoteFlow.button.nextStep" disabled={!!errorMessage} onPress={onPressContinue}>
            Continuer
          </Button>
        )
      }
    >
      <RadioBlockGroup<ValueType> choices={choices} value={value} id={id} onChange={onChange} />
      {errorMessage ? (
        <View marginTop="kitt.2">
          <Typography.Text color={theme.colors.danger} variant="regular" base="body-m">
            {errorMessage}
          </Typography.Text>
        </View>
      ) : null}
      {children}
    </ScreenTemplateWithoutForm>
  );
}
