import { Button } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { submitButtonTestId } from '../../../utils/submitButtons';

export function VehicleCoverageDateSubmitButton(): ReactNode {
  const { submit } = useForm();
  const { errors = {} } = useFormState();
  const hasError = Object.keys(errors).length > 0;

  return (
    <Button type="primary" disabled={hasError} testID={submitButtonTestId} onPress={submit}>
      <FormattedMessage defaultMessage="Continuer" id="VehicleAcquisitionDateSubmitButton.label" />
    </Button>
  );
}
