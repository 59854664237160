import { Typography, VStack, useBreakpointValue } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface PromoCodeAmountProps {
  amount?: number;
  customAmountLabel?: string;
}

interface TextProps {
  fontSize: number;
  strokeWidth: number;
  y: number;
}

function getTextProps(value: string): TextProps {
  if (value.length >= 3) {
    return {
      strokeWidth: 3,
      fontSize: 40,
      y: 35,
    };
  }

  return {
    strokeWidth: 4,
    fontSize: 56,
    y: 50,
  };
}

export function PromoCodeAmount({ amount = 0, customAmountLabel }: PromoCodeAmountProps): ReactNode {
  const isMedium = useBreakpointValue({ base: false, medium: true });

  const textProps = getTextProps(customAmountLabel || amount.toString());

  // Using overlapped texts to emulate outside outlines with svgs
  // see https://stackoverflow.com/a/7273346 answers

  return (
    <VStack justifyContent="center">
      <svg height={textProps.fontSize} width={customAmountLabel ? 160 : 135}>
        <text fontFamily="Transducer" fontStyle="black" fill="white" color="white" stroke="#000000" {...textProps}>
          {customAmountLabel || `${amount}€`}
        </text>
        <text fontFamily="Transducer" fontStyle="black" fill="white" color="white" {...textProps}>
          {customAmountLabel || `${amount}€`}
        </text>
      </svg>

      {isMedium ? null : (
        <Typography.Text style={{ fontFamily: 'Transducer', fontSize: 16 }} color="kitt.black">
          REMBOURSÉS
        </Typography.Text>
      )}
    </VStack>
  );
}
