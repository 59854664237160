import { VehiculePreSelectionType } from '../../answers';
import type { StateMachineConfig } from '../../types';
import { State } from '../../types';

export const brandSearch: Pick<StateMachineConfig, 'id' | 'context' | 'stateValue'> = {
  id: 'targetStep-branchSearch',
  context: {
    answers: {
      vehiculePreSelectionType: VehiculePreSelectionType.BRAND,
    },
    defaultAnswers: [],
    isLoading: false,
    availableBrands: [],
    availableModels: [],
    availableCarburants: [],
    availablePuissanceFiscales: [],
    availableVersions: [],
    hasReachedRestitution: false,
    isDisplayingFicNotification: false,
    hasReceivedQuoteMailNotification: false,
    reachedSummaryScreenSections: {},
    editingInsuranceRecord: {
      recordType: undefined,
      driver: undefined,
      index: 0,
    },
  },
  stateValue: State.VEHICULE_BRAND,
};
