import * as Sentry from '@sentry/react';
import type { Except } from 'type-fest';
import type { VehicleFsmAnswers } from '../fsm/answers';
import { api } from './api';

export type BrandName = string;
export type ModelName = string;

type EnergieValue = string;
type SRACode = string;
type VersionName = string;
type ImmatriculationNumber = string;
type HorsePowerValue = number;

export function listBrands(): Promise<BrandName[]> {
  return api<BrandName[]>('v2/vehicles/brands');
}

export interface Model {
  name: ModelName;
  puissances_fiscales: HorsePowerValue[];
  energies: EnergieValue[];
}

export interface ModelV2 {
  name: ModelName;
  taxHorsepowers: HorsePowerValue[];
  energies: EnergieValue[];
}

export async function listModelsWithPossibleValues(brand: string): Promise<Model[]> {
  const searchParams = new URLSearchParams({ brand });
  const models = await api<ModelV2[]>(`v2/vehicles/models/?${searchParams.toString()}`);
  return models.map(({ taxHorsepowers, ...model }) => ({ ...model, puissances_fiscales: taxHorsepowers }));
}

interface MergedVersion {
  ids: SRACode[];
  version: VersionName;

  tax_horsepower: number;
  energy: string;
  is_utility: boolean;
}

export interface Version extends Omit<MergedVersion, 'ids'> {
  id: SRACode;

  brand?: BrandName;
  model?: ModelName;
}

export interface VersionV2 {
  name: VersionName;
  codes: SRACode[];
}

export async function listVersions(
  brand: BrandName | undefined,
  model: ModelName | undefined,
  taxHorsepower: HorsePowerValue | undefined,
  energy: EnergieValue | undefined,
): Promise<Version[]> {
  if (!brand || !model || !taxHorsepower || !energy) return [];
  const searchParams = new URLSearchParams({
    brand,
    model,
    energy,
    taxHorsepower: taxHorsepower.toString(),
  });

  const versions = await api<VersionV2[]>(`v2/vehicles/versions?${searchParams.toString()}`);

  return versions.map((version) => {
    const sortedIds = [...version.codes].sort((a, b) => a.localeCompare(b));
    return {
      id: sortedIds[0],
      ids: sortedIds,
      energy,
      version: version.name,
      tax_horsepower: taxHorsepower,
      is_utility: false,
    };
  });
}

export async function filterValidEnergies(
  brand: BrandName | undefined,
  model: ModelName | undefined,
  taxHorsepower: HorsePowerValue | undefined,
  energies: EnergieValue[] | undefined,
): Promise<EnergieValue[]> {
  if (!brand || !model || !taxHorsepower || !energies) return energies || [];

  const versionPromises = energies.map((energy) =>
    listVersions(brand, model, taxHorsepower, energy).then((versions) => ({
      energy,
      versions,
    })),
  );

  const validEnergies = (await Promise.all(versionPromises))
    .filter(({ versions }) => versions.length > 0)
    .map(({ energy }) => energy);
  return validEnergies;
}

export interface VehicleSpecifications {
  brand: BrandName;
  model: ModelName;
  taxHorsepower: HorsePowerValue;
  energy: EnergieValue;
  startDate: string;
  endDate: string;
  version: VersionName;
}

export const getVersionDetails = async (versionId: string): Promise<Version> => {
  const specifications = await api<VehicleSpecifications>(`v2/vehicles/${versionId}/specifications`);

  return {
    id: versionId,
    brand: specifications.brand,
    model: specifications.model,
    energy: specifications.energy,
    version: specifications.version,
    tax_horsepower: specifications.taxHorsepower,
    is_utility: false,
  };
};

export enum DataNeoErrorCode {
  InvalidLicensePlate = 'INVALID_LICENSE_PLATE',
  LicensePlateNotFound = 'LICENSE_PLATE_NOT_FOUND',
  VersionNotFound = 'VERSION_NOT_FOUND',
  QuotaExceeded = 'QUOTA_EXCEEDED',
  // RateLimited = "RATE_LIMITED",
  APIError = 'API_ERROR',
}

const getErrorMessage = (error: DataNeoErrorCode | string): string => {
  switch (error) {
    case DataNeoErrorCode.InvalidLicensePlate:
      return "Merci de spécifier une plaque d'immatriculation valide";
    case DataNeoErrorCode.LicensePlateNotFound:
      return "Nos service ne sont pas en mesure de trouver votre plaque d'immatriculation";
    case DataNeoErrorCode.VersionNotFound:
      return 'Impossible de trouver une version correspondante à votre véhicule';
    case DataNeoErrorCode.QuotaExceeded:
      return 'Notre service de recherche par immatriculation est temporairement indisponible';
    case DataNeoErrorCode.APIError:
      return 'Une erreur est survenue durant la recherche. Veuillez réessayer';
    default:
      return 'Une erreur est survenue durant la recherche. Veuillez réessayer';
  }
};

export interface VehicleSuggestions {
  specifications: Except<VehicleSpecifications, 'startDate' | 'endDate' | 'version'> & { circulationDate: string };
  versions: VersionV2[];
}

export async function getVehicleByLicensePlate(
  licensePlate: ImmatriculationNumber,
): Promise<Partial<VehicleFsmAnswers>> {
  try {
    const fetchedVehicle = await api<VehicleSuggestions>('v2/vehicles/suggestions', {
      method: 'post',
      json: { licensePlate },
    });

    const versionName = fetchedVehicle.versions.length === 1 ? fetchedVehicle.versions[0].name : undefined;

    return {
      vehiculeImmatriculation: licensePlate,
      vehiculeMarque: fetchedVehicle.specifications.brand,
      vehiculeModele: fetchedVehicle.specifications.model,
      vehiculeCarburant: fetchedVehicle.specifications.energy,
      vehiculePuissanceFiscale: fetchedVehicle.specifications.taxHorsepower,
      vehiculeMiseEnCirculation: fetchedVehicle.specifications.circulationDate,
      vehiculeVersionOptions: fetchedVehicle.versions.map(({ name, codes }) => ({
        id: codes[0],
        version: name,
        energy: fetchedVehicle.specifications.energy,
        tax_horsepower: fetchedVehicle.specifications.taxHorsepower,
        is_utility: false,
      })),
      vehiculeVersion: fetchedVehicle.versions.length === 1 ? fetchedVehicle.versions[0].codes[0] : undefined,
      vehiculeVersionName: versionName,
    };
  } catch (error: any) {
    Sentry.captureException(error);

    throw new Error(getErrorMessage(error.message as string));
  }
}
