import { View } from '@ornikar/kitt-universal';
import type { FieldProps } from '@ornikar/react-forms-universal';
import { Field as FormField } from '@ornikar/react-forms-universal';
import type { ReactNode } from 'react';
import { LocalAutoCompleteInput } from '../../../components/LocalAutocompleteInput';
import { makeChoices } from '../../../fsm/answers';
import { CountryCode, countryCodeToCountryNameMapping } from '../../../utils/country';

const options = makeChoices(CountryCode, Object.entries(countryCodeToCountryNameMapping)) as {
  label: string;
  value: CountryCode;
}[];

options.sort((a, b) => a.label.localeCompare(b.label));

export interface BirthCountryFieldProps
  extends Pick<FieldProps<string, string, unknown, Record<string, unknown>>, 'label' | 'name' | 'validate'> {
  autoComplete: string;
}

export function BirthCountryField({
  label = 'Votre pays de naissance',
  name,
  validate,
  autoComplete,
}: BirthCountryFieldProps): ReactNode {
  return (
    <View zIndex={4}>
      <FormField
        autoComplete={autoComplete}
        choices={options}
        component={LocalAutoCompleteInput}
        label={label}
        name={name}
        testID="autocomplete.BirthCountryField.countryField"
        placeholder="Sélectionnez un pays"
        validate={validate}
        maxItemContainerHeight={370}
      />
    </View>
  );
}
