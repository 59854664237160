import { CheckCircleFillIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, InputIcon, InputText, LoaderIcon, Typography, VStack, theme } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import { type ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { PromoCodeCheckEligibilityResponse } from '../../apis/promoCode';
import { promoCodeCheckEligibility } from '../../apis/promoCode';
import type { OrnikarPackageLevel, PaymentPeriodicity } from '../../apis/types/Formula';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import { sendEvent } from '../../utils/mixpanel';
import { PromoCodeCard } from './PromoCodeCard';

interface PromoCodeProps {
  promoCode: string;
  setPromoCode: (promoCode: string) => void;
  promoCodeError?: string;
  setPromoCodeError: (promoCode: string | undefined) => void;
  promoCodeResponse: PromoCodeCheckEligibilityResponse | undefined;
  setPromoCodeResponse: (promoCodeResponse: PromoCodeCheckEligibilityResponse | undefined) => void;
  submissionId: string;
  paymentFrequency: PaymentPeriodicity;
  formula: OrnikarPackageLevel | undefined;
}

const fallbackMessage = "Bien tenté, mais ce code n'existe pas";
export function PromoCode({
  submissionId,
  promoCode,
  paymentFrequency,
  formula,
  setPromoCode,
  promoCodeError,
  setPromoCodeError,
  promoCodeResponse,
  setPromoCodeResponse,
}: PromoCodeProps): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleApply = useCallback(async () => {
    if (!promoCode) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await promoCodeCheckEligibility({
        submissionId,
        couponCode: promoCode,
        formula,
        paymentFrequency,
      });

      if (response.isEligible) {
        setPromoCodeError(undefined);
        setPromoCodeResponse(response);
        sendEvent('Promotional code applied', {
          promo_code_name: promoCode,
        });
      } else {
        setPromoCodeError(response.rejectedReason || fallbackMessage);
        setPromoCodeResponse(undefined);
      }
    } catch (error) {
      Sentry.captureException(error);
      setPromoCodeError(fallbackMessage);
      setPromoCodeResponse(undefined);
    }

    setIsSubmitting(false);
  }, [promoCode, setPromoCodeError, setPromoCodeResponse, submissionId, paymentFrequency, formula]);

  const handleDelete = async (): Promise<void> => {
    setPromoCode('');
    setPromoCodeResponse(undefined);
    setPromoCodeError(undefined);
  };

  return (
    <VStack space="kitt.2">
      <VStack space="kitt.1">
        <Typography.Text>
          <FormattedMessage id="promoCode.input.label" defaultMessage="Code promo" />
        </Typography.Text>
        <InputText
          value={promoCode}
          disabled={!!promoCodeResponse}
          right={
            promoCodeResponse?.isEligible || isSubmitting ? (
              <InputIcon
                icon={
                  promoCodeResponse?.isEligible ? <CheckCircleFillIcon fill={theme.colors.correct} /> : <LoaderIcon />
                }
              />
            ) : null
          }
          onChangeText={setPromoCode}
        />
        {promoCodeError ? (
          <Typography.Text base="body-small" color="danger">
            {promoCodeError}
          </Typography.Text>
        ) : null}
      </VStack>
      {!promoCodeError && promoCodeResponse ? (
        <>
          <PromoCodeCard
            amount={promoCodeResponse.amount}
            customCouponLabel={promoCodeResponse.customCouponLabel}
            generalConditionsURL={promoCodeResponse.generalConditionsURL}
            campaignStartDate={promoCodeResponse.campaignStartDate}
            campaignEndDate={promoCodeResponse.campaignEndDate}
            minimumPrimeAmount={promoCodeResponse.minimumPrimeAmount}
            promoCode={promoCode}
          />
          <Button stretch={!isMedium} disabled={!promoCode || isSubmitting} type="default" onPress={handleDelete}>
            <FormattedMessage id="promoCode.deleteButton.label" defaultMessage="Effacer" />
          </Button>
        </>
      ) : (
        <Button
          stretch={!isMedium}
          disabled={!promoCode || isSubmitting}
          icon={isSubmitting ? <LoaderIcon /> : undefined}
          type="default"
          onPress={handleApply}
        >
          <FormattedMessage id="promoCode.applyButton.label" defaultMessage="Appliquer" />
        </Button>
      )}
    </VStack>
  );
}
