import { InputText, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { filterValidEnergies } from '../../../apis/vehicules';
import { Dropdown } from '../../../components/Dropdown';
import { Field } from '../../../components/Field';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useAsync } from '../../../hooks/useAsync';
import { serviceUnavailable } from '../../../utils/errorMessages';
import { wait } from '../../../utils/wait';

export function CarburantField(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const [validEnergies, error, loading] = useAsync(() => {
    return filterValidEnergies(
      context.answers.vehiculeMarque,
      context.answers.vehiculeModele,
      context.answers.vehiculePuissanceFiscale,
      context.availableCarburants,
    );
  }, []);

  const choices = useMemo(() => {
    return (validEnergies || []).map((v) => ({ label: v, value: v }));
  }, [validEnergies]);

  const placeholderText = loading ? 'Chargement...' : 'Sélectionner';

  const onChange = async (value: string): Promise<void> => {
    // UX tricks
    await wait();

    send({ type: Event.ANSWER, answers: { vehiculeCarburant: value } });
  };

  if (error) {
    return (
      <>
        <Field disabled name="vehiculeCarburant" component={InputText} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  return (
    <div data-testid="div.vehicleCarburantScreen.CarburantField.fuelType">
      <Dropdown
        placeholder={placeholderText}
        values={choices}
        name="vehiculeCarburant"
        value={context.answers?.vehiculeCarburant}
        onChange={onChange}
      />
    </div>
  );
}
