import { CaretDownRegularIcon, CaretUpRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, Picker } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useField } from 'react-final-form';

interface DropdownValue<FieldValue extends string | number> {
  value: FieldValue;
  label: string;
}

interface DropdownProps<FieldValue extends string | number> {
  values: DropdownValue<FieldValue>[];
  label?: ReactNode;
  placeholder: ReactNode;
  value?: FieldValue;
  name?: string;
  testID?: string;
  onChange: (value: FieldValue) => void;
}

export function Dropdown<FieldValue extends string | number>({
  values,
  label,
  placeholder,
  name,
  testID,
  value,
  onChange,
  ...props
}: DropdownProps<FieldValue>): ReactNode {
  const initialValue = values.find((v) => v.value === value);
  const {
    input: { onBlur: blur },
  } = useField(name || '');

  return (
    <Picker<{ value: FieldValue; label: string }>
      shouldDisplayNativePickerOnIOS={false}
      title={label}
      checkSelectedItem={(selectedItem, item) => selectedItem === item}
      itemToString={(item) => item.label}
      renderTrigger={({ isOpen, selectedItem, onPress, ...rest }) => (
        <Button
          {...rest}
          stretch
          iconPosition="right"
          testID={testID}
          innerSpacing="space-between"
          icon={
            isOpen ? <CaretUpRegularIcon width={20} height={20} /> : <CaretDownRegularIcon width={20} height={20} />
          }
          onPress={onPress}
        >
          {selectedItem?.label || placeholder}
        </Button>
      )}
      triggerViewProps={{ width: '100%' }}
      initialValue={initialValue}
      onChange={(dropdownValue) => {
        if (dropdownValue) {
          onChange(dropdownValue.value);
        }
        blur();
      }}
      {...props}
    >
      {values?.map((pickerValue) => (
        <Picker.Option
          key={pickerValue.value}
          value={pickerValue}
          label={pickerValue.label}
          testID={pickerValue.value.toString()}
        >
          {pickerValue.label}
        </Picker.Option>
      ))}
    </Picker>
  );
}
