import { View } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { validateIban } from '../../../apis/validateIban';
import { CallbackModule } from '../../../components/CallbackModule';
import { ScreenTemplateWithBarsAndValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithBarsAndValidation';
import { useToast } from '../../../components/Toast/useToast';
import { WEB_CALLBACK_FEATURE } from '../../../constants/flagshipKeys';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import { useFsFlag } from '../../../setup/flagship';
import { sendEvent } from '../../../utils/mixpanel';
import { getSelectedOptionsFromAnswers } from '../../../utils/options';
import { IbanCard } from './IbanCard';
import { PaymentLegalCheckboxes } from './PaymentLegalCheckboxes';
import { UpFrontPaymentHighLight } from './UpFrontPaymentHighLight';
import styles from './styles.module.css';

export function IbanScreen(): ReactNode {
  const webCallbackFlag = useFsFlag<boolean>(WEB_CALLBACK_FEATURE, false);
  const isDesktop = useInsuranceDesktopMediaQuery();
  const { openToast } = useToast();

  const send = useSubscriptionFsmDispatch();

  const {
    context: { hasReceivedQuoteMailNotification, paymentPeriodicity, answers },
  } = useSubscriptionFsmState();

  const optionsSelected = getSelectedOptionsFromAnswers(answers);

  const sendMixpanelIbanAttemptEvent = (isValidIban: boolean) => {
    sendEvent('IBAN Attempt', {
      iban_check_status: isValidIban ? 'validated' : 'declined',
      options_selected: optionsSelected,
    });
  };

  useSendPageNameToSegment();

  const fieldNames = ['ibanNumber', 'ibanTitulaire', 'legalCheckbox1', 'legalCheckbox2', 'checkboxesVersion'];

  useEffect(() => {
    if (!hasReceivedQuoteMailNotification) {
      openToast({
        message: 'Nous vous avons envoyé votre devis par mail',
        timer: 5000,
        type: 'success',
      });

      send({ type: Event.QUOTE_MAIL_NOTIFICATION_RECEIVED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReceivedQuoteMailNotification]);

  const [isLoading, setIsLoading] = useState(false);
  const { submit } = useForm();
  const { values } = useFormState();

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    let isValid = false;

    try {
      isValid = await validateIban(values.ibanNumber as string);

      if (isValid) {
        submit();
      } else {
        openToast({
          message: "L'IBAN renseigné est invalide, veuillez en essayer un autre",
          timer: 5000,
          type: 'error',
        });
      }
    } catch (error) {
      Sentry.captureException(error);

      // @TODO: we probably should try to handle this in a more generic way
      openToast({
        message: 'Une erreur est survenue',
        timer: 5000,
        type: 'error',
        error: error as Error,
      });
    } finally {
      sendMixpanelIbanAttemptEvent(isValid);
      setIsLoading(false);
    }
  };

  const title =
    paymentPeriodicity === 'monthly'
      ? 'Préparez votre mandat SEPA en prévision de vos prochaines mensualités'
      : 'Préparez votre mandat SEPA en prévision de vos prochains prélèvements';

  return (
    <ScreenTemplateWithBarsAndValidation
      hideSaveQuoteButton
      fieldNames={fieldNames}
      title={title}
      submitButton={{ label: 'Valider', loading: isLoading }}
      sideBar={
        isDesktop && webCallbackFlag.getValue() ? (
          <View>
            <CallbackModule light />
          </View>
        ) : null
      }
      className={styles.Column}
      backButtonLabel="Étape précédente"
      onSubmit={handleSubmit}
    >
      <UpFrontPaymentHighLight />
      <IbanCard />
      {!isDesktop && webCallbackFlag.getValue() ? <CallbackModule light /> : null}
      <PaymentLegalCheckboxes />
    </ScreenTemplateWithBarsAndValidation>
  );
}
