import type { ReactNode } from 'react';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { accompaniedDrivingOptionsList } from '../../../constants/mappers/driving';
import type { YesNoQuestion } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';
import { wait } from '../../../utils/wait';
import { RadioScreenTemplateWithoutForm } from '../../ScreenTemplates/RadioScreenTemplateWithoutForm';
import type { HighlightContentProps, PrimaryOrSecondaryScreenTemplateType } from '../types';

function HighlightContent({ isPrimaryDriver = false, civility }: HighlightContentProps) {
  return isPrimaryDriver ? (
    <div>
      Si vous avez obtenu votre permis en conduite accompagnée, vous{' '}
      <b>bénéficiez d&apos;un tarif légèrement plus bas</b> compte tenu de votre apprentissage avancé.
    </div>
  ) : (
    <div>
      {civility === Civility.Homme ? 'S’il' : 'Si elle'} a obtenu son permis en conduite accompagnée, vous{' '}
      <b>bénéficiez d&apos;un tarif légèrement plus bas</b> compte tenu de son apprentissage avancé.
    </div>
  );
}

export function AccompaniedDrivingTemplate({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;
  const isSecondaryDriver = driverType === Driver.Secondary;
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const civility = context.answers[`${driverType}Driver`]?.civility;
  const attribut = civility === Civility.Homme ? 'il' : 'elle';
  const isLicenseDateInFuture = useIsLicenseDateInFuture(driverType);

  const handleChange = async (value?: YesNoQuestion): Promise<void> => {
    // UX tricks
    await wait();

    if (isPrimaryDriver) {
      send({
        type: Event.ANSWER,
        answers: { primaryDriver: { ...context.answers.primaryDriver, accompaniedDriving: value } },
      });
    } else if (isSecondaryDriver) {
      send({
        type: Event.ANSWER,
        answers: { secondaryDriver: { ...context.answers.secondaryDriver, accompaniedDriving: value } },
      });
    }
  };

  return (
    <RadioScreenTemplateWithoutForm
      title={
        isPrimaryDriver ? (
          <div>{isLicenseDateInFuture ? 'Aurez' : 'Avez'}-vous fait de la conduite accompagnée&nbsp;?</div>
        ) : (
          <div>A-t-{attribut} fait de la conduite accompagnée&nbsp;?</div>
        )
      }
      highlightTitle="Besoin de précision ?"
      highlightContent={
        isPrimaryDriver ? <HighlightContent isPrimaryDriver /> : <HighlightContent civility={civility} />
      }
      fieldName={`${driverType}Driver.accompaniedDriving`}
      choices={accompaniedDrivingOptionsList}
      defaultValue={
        isPrimaryDriver
          ? context.answers?.primaryDriver?.accompaniedDriving
          : context.answers?.secondaryDriver?.accompaniedDriving
      }
      id="accompaniedDriving"
      onChange={handleChange}
      onPressContinue={() => {
        if (isPrimaryDriver) {
          send({
            type: Event.ANSWER,
            answers: { primaryDriver: context.answers?.primaryDriver },
          });
        } else if (isSecondaryDriver) {
          send({
            type: Event.ANSWER,
            answers: { secondaryDriver: context.answers?.secondaryDriver },
          });
        }
      }}
    />
  );
}
