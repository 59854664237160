import { useMachine } from '@xstate/react';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { Event, EventData, SCXML, SingleOrArray, State, StateMachine } from 'xstate';
import type { Package } from '../apis/types/Formula';
import type { Context, Events, Schema } from './types';
import { Section } from './types';

export type MachineState = State<Context, Events, Schema>;
export type MachineDispatch = (
  event: SingleOrArray<Event<Events>> | SCXML.Event<Events>,
  payload?: EventData | undefined,
) => MachineState;

interface ContextValue {
  state: MachineState;
  send: MachineDispatch;
}

export const SubscriptionFsmContext = createContext<ContextValue | null>(null);

interface SubscriptionFsmProviderProps {
  children: NonNullable<ReactNode>;
  machine: StateMachine<Context, Schema, Events>;
}

export function SubscriptionFsmProvider({ children, machine }: SubscriptionFsmProviderProps): ReactNode {
  const [state, send] = useMachine(machine);
  const value = useMemo(() => ({ state, send }), [state, send]);

  return <SubscriptionFsmContext.Provider value={value}>{children}</SubscriptionFsmContext.Provider>;
}

export function useSubscriptionFsmState(): MachineState {
  return useContext(SubscriptionFsmContext)?.state ?? ({} as MachineState);
}

export function useSubscriptionFsmStateValue(): MachineState['value'] {
  return useSubscriptionFsmState().value;
}

export function useSubscriptionFsmDispatch(): MachineDispatch {
  return useContext(SubscriptionFsmContext)!.send;
}

export function useCurrentFormule(): Package {
  const state = useSubscriptionFsmState();
  const { formulePickedObject } = state.context;

  if (formulePickedObject) return formulePickedObject;
  throw new Error('Attempt to useCurrentFormule before formules');
}

export type TimeScale = 'mois' | 'an' | 'semestre' | 'trimestre';

/**
 * Formate price in euro.
 *
 * @param price price (in cents)
 * @returns formated price
 */
export function formatPrice(price: number): string {
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price / 100).replace(/,00/, '');
}

export const initializeContext = (initialContext?: Partial<Context>): Context => {
  return {
    answers: {
      ...initialContext?.answers,
      kilometersPerYear: initialContext?.answers?.kilometersPerYear ?? 15000,
    },
    defaultAnswers: initialContext?.defaultAnswers || [],
    lastQuoteRequest: initialContext?.lastQuoteRequest,

    isLoading: Boolean(initialContext?.isLoading),

    availableBrands: initialContext?.availableBrands || [],
    availableModels: initialContext?.availableModels || [],
    availableCarburants: initialContext?.availableCarburants || [],
    availablePuissanceFiscales: initialContext?.availablePuissanceFiscales || [],
    availableVersions: initialContext?.availableVersions || [],

    hasReachedRestitution: initialContext?.hasReachedRestitution || false,
    isDisplayingFicNotification: initialContext?.isDisplayingFicNotification || false,
    formulePickedObject: initialContext?.formulePickedObject || undefined,
    hasReceivedQuoteMailNotification: false,

    tarificationResult: initialContext?.tarificationResult || undefined,
    devisCreationResult: initialContext?.devisCreationResult || undefined,
    subscribeResult: initialContext?.subscribeResult || undefined,
    quoteOptions: initialContext?.quoteOptions || undefined,
    reachedSummaryScreenSections: initialContext?.reachedSummaryScreenSections || {
      [Section.VEHICULE]: false,
      [Section.DRIVING]: false,
      [Section.PRIMARY_DRIVER]: false,
      [Section.SECONDARY_DRIVER]: false,
    },
    error: undefined,
    isFetchingLicensePlate: undefined,
    postPaymentResult: initialContext?.postPaymentResult || undefined,
    quoteId: initialContext?.quoteId,
    leadId: initialContext?.leadId,
    agentEmail: initialContext?.agentEmail,
    isFromAggregator: initialContext?.isFromAggregator || false,

    /** RAG SPECIFICS */
    editingInsuranceRecord: initialContext?.editingInsuranceRecord || { index: 0 },
    paymentPeriodicity: initialContext?.paymentPeriodicity,
    segmentAnonymousId: initialContext?.segmentAnonymousId,
    segmentUserId: initialContext?.segmentUserId,

    /** Block effective date in the past * */
    selectedPackageName: initialContext?.selectedPackageName,
    minPackageEffectiveDate: initialContext?.minPackageEffectiveDate,
  };
};
