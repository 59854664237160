import { Button } from '@ornikar/kitt-universal';
import { useState } from 'react';
import type { ChangeEvent, ReactNode } from 'react';
import { Field } from '../../../components/Field';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { SliderInput } from '../../../components/SliderInput';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';
import { submitButtonTestId } from '../../../utils/submitButtons';

export function DrivingKilometersPerYearScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const valueFromContext = context?.answers?.kilometersPerYear;

  const [currentValue, setCurrentValue] = useState(valueFromContext);

  const isLicenseDateInFuture = useIsLicenseDateInFuture(Driver.Primary);
  const title = `Approximativement, combien de kilomètres ${
    isLicenseDateInFuture ? 'ferez' : 'faites'
  }-vous par an\u00A0?`;

  return (
    <ScreenTemplateWithoutForm
      title={title}
      highlightTitle="Vous ne connaissez pas votre kilométrage exact ?"
      highlightContent={
        <div>
          Ce n&apos;est pas grave, nous souhaitons juste une estimation, car cette information aura un impact sur votre
          tarif.
          <br />
          <br />
          <b>Astuce :</b> en moyenne, les Français parcourent 15 000 km/an. Ce chiffre est généralement plus faible pour
          les personnes vivant en ville.
          <br />
          <br />
          Si vous avez donné un kilométrage trop haut, vous pourrez changer cette information par téléphone avec un
          autre conseiller expert, il pourra également être ajusté au renouvellement de votre contrat.
        </div>
      }
      submitButtonComponent={
        <Button
          type="primary"
          testID={submitButtonTestId}
          onPress={() => {
            send({ type: Event.ANSWER, answers: { kilometersPerYear: currentValue } });
          }}
        >
          Continuer
        </Button>
      }
    >
      <Field
        defaultValue={valueFromContext}
        component={SliderInput}
        name="kilometersPerYear"
        min={1000}
        minLabel="Moins de 1 000 km"
        max={50000}
        maxLabel="Plus de 50 000 km"
        step={1000}
        formatValue={(val: number) => {
          if (val === 1000) {
            return '≤ 1 000';
          }

          if (val === 50000) {
            return '≥ 50 000';
          }

          return val.toLocaleString('fr-FR').replace('\u202F', ' ');
        }}
        unit="km"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setCurrentValue(Number(e.target.value));
        }}
      />
    </ScreenTemplateWithoutForm>
  );
}
