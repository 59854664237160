import type { DoneInvokeEvent } from 'xstate';
import { assign } from 'xstate';
import { HamonWantType } from '../../apis/types/LongQuoteAnswers';
import type { TarificationResult, TarificationResultKO } from '../../apis/types/Quotation';
import { replaceUrlSearchParam } from '../../utils/urlSearchParam';
import type { Context } from '../types';
import { FsmErrorCode } from '../types';
import type { FormuleSelectedAssign } from './types';

export const clearDeductibles: FormuleSelectedAssign = assign((context) => {
  return {
    answers: {
      ...context.answers,
      deductibles: undefined,
    },
  };
});

export const saveQuoteResult: FormuleSelectedAssign = assign((context, event) => {
  return {
    answers: {
      ...context.answers,
      ...event.answers,
      hamonWant: event.results.hamonPossible ? HamonWantType.Yes : HamonWantType.No,
    },
    paymentPeriodicity: event.paymentPeriodicity,
    devisCreationResult: event.results,
    quoteOptions: event.options,
  };
});

export const resetError = assign<Context, any>({
  error: undefined,
});

export const resetSelectedPackageName = assign<Context, any>({
  selectedPackageName: undefined,
});

export const resetTarificationResult = assign<Context, any>({
  tarificationResult: undefined,
});

export const showFicNotification = assign<Context, any>({
  isDisplayingFicNotification: true,
});

export const resetFicMailDisplay = assign<Context, any>({
  isDisplayingFicNotification: false,
});

export const resetQuoteMailNotification = assign<Context, any>({
  hasReceivedQuoteMailNotification: false,
});

export const markQuoteMailNotificationAsReceived = assign<Context, any>({
  hasReceivedQuoteMailNotification: true,
});

export const onTarificationError = assign<Context, DoneInvokeEvent<TarificationResultKO>>(() => ({
  error: FsmErrorCode.NO_PROVIDER_AVAILABLE,
}));

export const onTarificationResult = assign<Context, DoneInvokeEvent<TarificationResult>>((context, event) => {
  if (event.data.isOk) {
    const { segmentUserId, ...tarificationResult } = event.data;

    const searchParams = new URLSearchParams(window.location.search);

    // If we have a devis in the url, we replace it with the new one
    if (searchParams.get('devis')) {
      const { submissionId, submissionNumber } = tarificationResult;
      const devis = `${submissionId.split('pc:')[1]}~${submissionNumber}`;
      replaceUrlSearchParam('devis', devis);
    }

    return {
      answers: {
        ...context.answers,
        dateEffetSouhaite: tarificationResult.effectiveDate,
      },
      lastQuoteRequest: {
        ...context.answers,
      },
      tarificationResult,
      segmentUserId,
    };
  }

  return { tarificationResult: event.data };
});
