export const WEB_CALLBACK_FEATURE = 'web_callback_feature';
export const PROMO_CODE_BANNER_DISPLAY = 'promo_code_banner_display';
export const PERSONAL_INFORMATION_BANNER = 'personal_information_banner';
export const CREDIT_CARD_SCREEN_UPDATE = 'credit_card_screen_update';
export const REGISTRATION_DOCUMENT_PARTNER_LINK = 'registration_document_partner_link';
export const OPTIONS_PRESENTATION_MODE = 'options_presentation_mode';

/* MRH Redirection */
export const MRH_REDIRECTION_FEATURE = 'mrh_redirection_feature';
export const FORMULA_SCREEN_MRH_REDIRECTION_FEATURE = 'formula_screen_mrh_redirection_feature';
export const NO_OFFER_SCREEN_MRH_REDIRECTION_FEATURE = 'no_offer_screen_mrh_redirection_feature';
export const WELCOME_SCREEN_MRH_REDIRECTION_FEATURE = 'welcome_screen_mrh_redirection_feature';
