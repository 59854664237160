import type { ReactNode } from 'react';
import type { LogementType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { housingTypeOptionsList } from '../../../constants/mappers/driving';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

export function DrivingHousingTypeScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const handleChange = async (housingType?: LogementType): Promise<void> => {
    await wait();

    send({ type: Event.ANSWER, answers: { housingType } });
  };

  return (
    <RadioScreenTemplateWithoutForm
      fieldName="housingType"
      title="Vous logez dans..."
      defaultValue={answers.housingType}
      choices={housingTypeOptionsList}
      id="housingType"
      onChange={handleChange}
      onPressContinue={() => {
        send({ type: Event.ANSWER, answers: { housingType: answers.housingType } });
      }}
    />
  );
}
