import { Button } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { submitButtonTestId } from '../../../utils/submitButtons';
import { CarburantField } from './CarburantField';

export function VehicleCarburantScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  return (
    <ScreenTemplateWithoutForm
      title={
        <span>
          Quel est son
          <br />
          carburant / énergie&nbsp;?
        </span>
      }
      highlightTitle="Où trouver le type de carburant ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère P.3</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      submitButtonComponent={
        answers.vehiculeCarburant ? (
          <Button
            type="primary"
            testID={submitButtonTestId}
            onPress={() => {
              send({ type: Event.ANSWER, answers: { vehiculeCarburant: answers.vehiculeCarburant } });
            }}
          >
            Continuer
          </Button>
        ) : null
      }
    >
      <CarburantField />
    </ScreenTemplateWithoutForm>
  );
}
