import type { ReactNode } from 'react';
import { ParkingType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

const parkingChoices = makeChoices(ParkingType, [
  [ParkingType.public, 'Dans la rue ou dans un parking public'],
  [
    ParkingType.parking,
    "Dans un parking privé clos (par exemple le parking d'une résidence à ciel ouvert mais fermé par une grille)",
  ],
  [ParkingType.garage, 'Dans un box privé clos'],
]);

export function DrivingVehicleParkingScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const handleChange = async (parkingLocation?: ParkingType): Promise<void> => {
    // Ui tricks
    await wait();

    send({ type: Event.ANSWER, answers: { parkingLocation } });
  };

  return (
    <RadioScreenTemplateWithoutForm
      defaultValue={context.answers?.parkingLocation}
      title="Le soir, où sera garé votre véhicule&nbsp;?"
      highlightTitle="Besoin de précision ?"
      highlightContent={
        <div>
          Votre réponse nous permettra de vous proposer le niveau de remboursement le plus adapté aux risques encourus
          par votre véhicule.
        </div>
      }
      fieldName="parkingLocation"
      choices={parkingChoices}
      id="drivingVehicleParkingOptions"
      onChange={handleChange}
      onPressContinue={() => {
        send({ type: Event.ANSWER, answers: { parkingLocation: context.answers?.parkingLocation } });
      }}
    />
  );
}
