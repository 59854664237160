import { InputText, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { listBrands } from '../../../../apis/vehicules';
import type { FieldProps } from '../../../../components/Field';
import { FieldV2 } from '../../../../components/FieldV2';
import { LocalAutoCompleteInput } from '../../../../components/LocalAutocompleteInput';
import type { VehicleFormValues } from '../../../../forms/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../../fsm/context';
import { Event } from '../../../../fsm/types';
import { useAsync } from '../../../../hooks/useAsync';
import { serviceUnavailable } from '../../../../utils/errorMessages';
import { sendNoOptionsFoundEvent } from '../../../../utils/mixpanel';
import { wait } from '../../../../utils/wait';

interface BrandAutoCompleteProps extends Pick<FieldProps<string>, 'label' | 'name' | 'validate'> {
  disabled?: boolean;
}

export function BrandAutoComplete(props: BrandAutoCompleteProps): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const [brands, brandsError, brandsLoading] = useAsync(() => {
    if (!props.disabled) {
      return listBrands();
    }

    return Promise.resolve([]);
  }, [props.disabled]);

  const brandsChoices = useMemo(() => {
    return brands?.map((brand) => ({ label: brand, value: brand })) || [];
  }, [brands]);

  useEffect(() => {
    if (!brandsLoading && (!brands || brands.length === 0)) {
      sendNoOptionsFoundEvent(props.name as keyof VehicleFormValues);
    }
  }, [brandsLoading, brands, props.name]);

  if (brandsError) {
    return (
      <>
        <FieldV2 {...props} disabled component={InputText} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  if (props.disabled) {
    return <FieldV2 {...props} component={InputText} />;
  }

  const handleChange = async (value: string): Promise<void> => {
    // UX tricks
    await wait();
    send({ type: Event.ANSWER, answers: { vehiculeMarque: value, availableBrands: brands ?? [] } });
  };

  return (
    <LocalAutoCompleteInput
      name=""
      isLoading={brandsLoading}
      placeholder={brandsLoading ? 'Chargement...' : ''}
      testID="autocomplete.BrandAutocomplete.vehicleBrand"
      choices={brandsChoices}
      value={answers?.vehiculeMarque ?? ''}
      onChange={handleChange}
      onBlur={() => {
        // noop
      }}
    />
  );
}
