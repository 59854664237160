import { Form } from '@ornikar/react-forms-universal';
import { isEqual } from 'lodash';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import type { PrimaryOrSecondaryScreenTemplateType } from '../../../components/drivingSection/types';
import { validateHolderInformations } from '../../../forms/validation/common/holderInformations';
import type { DriverAnswers } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { mapAnswersToFormValues } from '../../../fsm/mappers/mapAnswersToFormValues';
import { Driver, Event } from '../../../fsm/types';
import { formatPhone } from '../../../utils/formatPhone';
import { getComposedKeys } from '../../../utils/objects';
import { DriverDetailsScreenFormContent } from './DriverDetailsScreenFormContent';
import { DriverDetailsSubmitButton } from './DriverDetailsSubmitButton';

export function DriverDetailsScreen({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;

  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const answers = context?.answers;

  const defaultValues: Record<string, unknown> = {
    [`${driverType}Driver`]: {
      firstName: isPrimaryDriver ? answers?.primaryDriver?.firstName : answers?.secondaryDriver?.firstName,
      lastName: isPrimaryDriver ? answers?.primaryDriver?.lastName : answers?.secondaryDriver?.lastName,
      civility: isPrimaryDriver ? answers?.primaryDriver?.civility : answers?.secondaryDriver?.civility,
      birthDate: isPrimaryDriver ? answers?.primaryDriver?.birthDate : answers?.secondaryDriver?.birthDate,
    },
  };

  if (isPrimaryDriver) {
    defaultValues.primaryDriver = {
      ...(defaultValues.primaryDriver as Record<string, unknown>),
      birthCity: answers?.primaryDriver?.birthCity,
      birthCountryCode: answers?.primaryDriver?.birthCountryCode,
      profession: answers?.primaryDriver?.profession,
      maritalStatus: answers?.primaryDriver?.maritalStatus,
    };
    defaultValues.subscriberEmail = answers?.subscriberEmail;
    defaultValues.subscriberPhone = answers?.subscriberPhone;
    defaultValues.subscriberAutoCompletedAddress = answers?.subscriberCompleteAdress;
  }

  const fieldNames = getComposedKeys(defaultValues);

  const [modalVisible, setModalVisible] = useState(false);

  const handleSubmit = async (values: Record<string, unknown>): Promise<undefined | Record<string, unknown>> => {
    const { privacyPolicyAccepted, ...restValues } = values;
    const { privacyPolicyAccepted: lastPrivacyPolicyAccepted, ...restLastQuoteRequest } =
      context.lastQuoteRequest || {};

    if (context.tarificationResult && isEqual(mapAnswersToFormValues(restLastQuoteRequest), restValues)) {
      send({ type: Event.CONTINUE });
    } else {
      if (isPrimaryDriver) {
        const { primaryDriver, subscriberAutoCompletedAddress, subscriberEmail } = values;

        const subscriberPhone = formatPhone((values.subscriberPhone as string) ?? '');

        if (!subscriberAutoCompletedAddress) {
          throw new Error('No address specified');
        }

        const validationErrors = await validateHolderInformations(
          subscriberAutoCompletedAddress as GeocodingDto,
          (subscriberEmail as string) ?? '',
          subscriberPhone,
        );

        if (validationErrors) {
          if (validationErrors?.isEmailDomainBlacklisted) {
            setModalVisible(true);
          }

          return validationErrors;
        }

        const { formattedAddress, street, zipCode, city } = subscriberAutoCompletedAddress as GeocodingDto;

        send({
          type: Event.ANSWER,
          answers: {
            primaryDriver: { ...context?.answers.primaryDriver, ...(primaryDriver as DriverAnswers) },
            subscriberFirstName: (primaryDriver as DriverAnswers).firstName,
            subscriberLastName: (primaryDriver as DriverAnswers).lastName,
            subscriberPhone,
            subscriberEmail: subscriberEmail as string,
            subscriberCompleteAdress: formattedAddress,
            subscriberAdress1: street,
            subscriberCodePostal: zipCode,
            subscriberCommune: city,
            privacyPolicyAccepted: true,
            hasAcceptedCommercialCommunication: !!values.hasAcceptedCommercialCommunication,
            hasAcceptedPartnersCommunication: !!values.hasAcceptedPartnersCommunication,
          },
        });

        return undefined;
      }
      // handle secondary driver submit
      const { secondaryDriver } = values;

      send({
        type: Event.ANSWER,
        answers: { secondaryDriver: { ...context?.answers.secondaryDriver, ...(secondaryDriver as DriverAnswers) } },
      });
    }
    return undefined;
  };

  return (
    <Form fieldNames={fieldNames} initialValues={defaultValues} onSubmit={handleSubmit}>
      <ScreenTemplateWithoutForm
        title={
          isPrimaryDriver ? (
            <div>
              Ça y est votre tarif est prêt ! Pour l&apos;obtenir, il ne vous reste plus qu&apos;à remplir ces dernières
              informations.
            </div>
          ) : (
            <div>
              Afin que ce conducteur bénéficie de votre couverture, pouvez-vous nous en dire plus sur lui&nbsp;?
            </div>
          )
        }
        submitButtonComponent={<DriverDetailsSubmitButton isPrimaryDriver={isPrimaryDriver} />}
      >
        <DriverDetailsScreenFormContent
          driverType={driverType}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
