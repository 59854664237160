import { type ReactNode, useState } from 'react';
import { TitulaireCarteGriseType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { ownerVehicleRegistrationOptionsList } from '../../../constants/mappers/driving';
import {
  RegistrationDocumentErrorMessage,
  expectingCompanyOwner,
} from '../../../forms/validation/sections/driving/registrationDocumentOwner';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

const choices = ownerVehicleRegistrationOptionsList.filter(
  ({ value }) => value !== TitulaireCarteGriseType.Souscripteur,
);

export function DrivingRegistrationDocumentOwnerScreen(): ReactNode {
  const [hasError, setHasError] = useState(false);
  const send = useSubscriptionFsmDispatch();
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const handleChange = async (value?: TitulaireCarteGriseType): Promise<void> => {
    if (!value) return;

    // validation : The owner of the vehicle can only be a credit company in case of a LDD or LOA
    // funding method
    if (expectingCompanyOwner(answers, value)) {
      setHasError(true);
      return;
    }

    setHasError(false);

    // UI Tricks
    await wait();
    send({ type: Event.ANSWER, answers: { titulaireCartegrise: value } });
  };

  return (
    <RadioScreenTemplateWithoutForm<TitulaireCarteGriseType>
      title="Qui est inscrit sur la carte grise (certificat d’immatriculation) du véhicule ?"
      fieldName="titulaireCartegrise"
      choices={choices}
      highlightTitle="Précision importante"
      defaultValue={answers?.titulaireCartegrise}
      errorMessage={hasError ? <RegistrationDocumentErrorMessage /> : undefined}
      highlightContent={
        <div>
          Vérifiez bien cette information, présente sur le <b>champ C1</b> de la carte grise (certificat
          d’immatriculation) du véhicule.
          <br />
          <br />
          Lors de votre souscription, vous devrez envoyer les permis de conduire des personnes inscrites sur la carte
          grise du véhicule pour activer votre assurance.
        </div>
      }
      id="titulaireCartegrise"
      onChange={handleChange}
      onPressContinue={() => {
        send({ type: Event.ANSWER, answers: { titulaireCartegrise: answers?.titulaireCartegrise } });
      }}
    />
  );
}
