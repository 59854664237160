import { getIn } from 'final-form';
import type { ReactNode } from 'react';
import { useFormState } from 'react-final-form';
import type { ScreenTemplateProps } from '../ScreenTemplate';
import { ScreenTemplate } from '../ScreenTemplate';

interface ScreenTemplateWithValidationProps extends ScreenTemplateProps {
  fieldNames?: string[];
  shouldDisableSubmitButton?: boolean;
}

export function ScreenTemplateWithValidation({
  fieldNames,
  submitButton,
  shouldDisableSubmitButton = true,
  ...props
}: ScreenTemplateWithValidationProps): ReactNode {
  const { errors = {} } = useFormState();
  const hasErrors = fieldNames && fieldNames.some((fieldName) => getIn(errors, fieldName)); // We need getIn() to resolve complex path like "something[0].foo"

  return (
    <ScreenTemplate
      {...props}
      submitButton={{
        ...submitButton,
        disabled: shouldDisableSubmitButton ? hasErrors : false,
      }}
      onBeforeSubmit={() => {
        if (hasErrors) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }}
    />
  );
}
