import { InputText, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import type { Choice, LocalAutoCompleteInputProps } from '../../../components/LocalAutocompleteInput';
import { LocalAutoCompleteInput } from '../../../components/LocalAutocompleteInput';
import { ExitDoorLink } from '../../../components/exitDoor/ExitDoorLink';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { serviceUnavailable } from '../../../utils/errorMessages';
import { sendNoOptionsFoundEvent } from '../../../utils/mixpanel';
import { wait } from '../../../utils/wait';
import styles from './styles.module.css';

const NO_VERSION_FOUND_PLACEHOLDER = 'Pas de version disponible';
const NO_VERSION_FOUND_ERROR_MESSAGE =
  'Il doit s’agir d’une erreur dans la saisie des réponses précédentes. Retournez en arrière pour vérifier. Si le problème persiste, appelez-nous.';

export function VersionAutoComplete(props: Pick<FieldProps<string>, 'name' | 'label' | 'validate'>): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const versions = context.availableVersions;

  const versionsRequestError = versions == null;

  const hasVersions = versions?.length > 0;

  const placeholder = hasVersions ? 'Sélectionner' : NO_VERSION_FOUND_PLACEHOLDER;

  const choices = useMemo<Choice<string>[]>(
    () => versions?.map(({ version, id }) => ({ label: version, value: id })) || [],
    [versions],
  );

  const setVehicleVersionDefaultValue = (): void => {
    send(Event.DEFAULT_ANSWER, {
      answers: {
        vehiculeVersion: versions[0].id,
        vehiculeIsUtility: versions[0].is_utility,
      },
    });
  };

  const onChange = async (value: any): Promise<void> => {
    const selectedVersion = versions?.find((version) => version.id === value);

    // makes the auto-submit feels more natural
    await wait();

    send(Event.ANSWER, {
      answers: {
        vehiculeVersion: selectedVersion?.id,
        vehiculeIsUtility: selectedVersion?.is_utility,
      },
    });
  };

  useEffect(() => {
    if (!hasVersions) {
      sendNoOptionsFoundEvent('vehiculeVersion');
    }
  }, [versions, hasVersions]);

  if (!hasVersions) {
    return (
      <>
        <Field
          {...props}
          disabled
          component={InputText}
          placeholder={versionsRequestError ? '' : NO_VERSION_FOUND_PLACEHOLDER}
        />
        <span className={styles.ErrorMessage}>
          {versionsRequestError ? serviceUnavailable : NO_VERSION_FOUND_ERROR_MESSAGE}
        </span>
      </>
    );
  }

  return (
    <VStack space="kitt.4" marginBottom={{ base: 'kitt.8', large: 0 }} zIndex={1}>
      <Field<string, LocalAutoCompleteInputProps<string>>
        {...props}
        component={LocalAutoCompleteInput}
        disabled={choices.length === 0}
        placeholder={placeholder}
        choices={choices}
        testID="autocomplete.VersionAutocomplete.vehicleVersion"
        onChange={onChange}
      />
      <ExitDoorLink onPress={setVehicleVersionDefaultValue}>Je ne connais pas la version du véhicule.</ExitDoorLink>
    </VStack>
  );
}
