import { IllustratedIcon, Light } from '@ornikar/illustrated-icons';
import {
  Checkbox,
  Highlight,
  InputText,
  RadioButtonGroup,
  Stack,
  Typography,
  TypographyLink,
  VStack,
} from '@ornikar/kitt-universal';
import { Field as FormField } from '@ornikar/react-forms-universal';
import { useComposeValidators, useRequiredValidator } from '@ornikar/react-validators';
import { capitalize } from 'lodash';
import { type Dispatch, type ReactNode, type SetStateAction, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { AddressAutocompleteFormField } from '../../../components/AddressAutocompleteFormField';
import { DateField } from '../../../components/DateField';
import { PhoneInput } from '../../../components/PhoneInput';
import { Row } from '../../../components/Row';
import type { PrimaryOrSecondaryScreenTemplateType } from '../../../components/drivingSection/types';
import { PERSONAL_INFORMATION_BANNER } from '../../../constants/flagshipKeys';
import { useBirthDateValidator } from '../../../forms/validation/sections/informations/birthDate';
import { useSubscriberEmailValidator } from '../../../forms/validation/sections/informations/email';
import { usePhoneNumberValidator } from '../../../forms/validation/sections/informations/phoneNumber';
import { useFirstNameMaxLengthValidator } from '../../../forms/validation/sharedValidators';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { Driver } from '../../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useFsFlag } from '../../../setup/flagship';
import { BirthCountryField } from './BirthCountryField';
import { MaritalStatusField } from './MaritalStatus';
import { ProfessionField } from './ProfessionField';
import { EmailErrorModal } from './components/EmailErrorModal';
import styles from './styles.module.css';

interface DriverDetailsScreenFormContentProps extends PrimaryOrSecondaryScreenTemplateType {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

export function DriverDetailsScreenFormContent({
  driverType,
  modalVisible,
  setModalVisible,
}: DriverDetailsScreenFormContentProps): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;
  const personalInformationFlag = useFsFlag<boolean>(PERSONAL_INFORMATION_BANNER, false, {
    shouldSendVariationToMixpanel: true,
  });

  const requiredValidator = useRequiredValidator();
  const isDesktop = useInsuranceDesktopMediaQuery();

  const { context } = useSubscriptionFsmState();
  const { values } = useFormState();

  const firstNameValidator = useComposeValidators(requiredValidator, useFirstNameMaxLengthValidator());
  const birthDateValidator = useBirthDateValidator(
    // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
    context.answers[`${driverType}Driver`]?.licenseDate!,
    context.answers.dateEffetSouhaite!,
    // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
    context.answers[`${driverType}Driver`]?.accompaniedDriving!,
  );
  const emailValidator = useSubscriberEmailValidator();

  const phoneNumberValidator = usePhoneNumberValidator();
  const initialFormattedAddress: GeocodingDto = useMemo(
    () => ({
      street: context.answers.subscriberAdress1 || '',
      city: context.answers.subscriberCommune || '',
      zipCode: context.answers.subscriberCodePostal || '',
      formattedAddress: context.answers.subscriberCompleteAdress || '',
    }),
    [
      context.answers.subscriberAdress1,
      context.answers.subscriberCommune,
      context.answers.subscriberCodePostal,
      context.answers.subscriberCompleteAdress,
    ],
  );

  return (
    <>
      {personalInformationFlag.getValue() && (
        <VStack marginBottom={isDesktop ? 'kitt.8' : 'kitt.6'}>
          <Highlight>
            <Stack direction="row">
              <VStack marginRight="kitt.2">
                <IllustratedIcon icon={<Light />} />
              </VStack>
              <Typography.Paragraph>
                Assurez-vous de communiquer des renseignements exacts avant de continuer votre devis. Vous en aurez
                besoin pour finaliser votre souscription.
              </Typography.Paragraph>
            </Stack>
          </Highlight>
        </VStack>
      )}
      <Row>
        <FormField
          component={InputText}
          name={`${driverType}Driver.firstName`}
          label={isPrimaryDriver ? 'Votre prénom' : 'Son prénom'}
          validate={firstNameValidator}
          format={capitalize}
        />
        <FormField
          component={InputText}
          name={`${driverType}Driver.lastName`}
          label={isPrimaryDriver ? 'Votre nom' : 'Son nom'}
          validate={requiredValidator}
          format={capitalize}
        />
      </Row>
      <Row>
        <FormField
          component={RadioButtonGroup}
          value={Civility.Homme}
          name={`${driverType}Driver.civility`}
          label={isPrimaryDriver ? 'Votre civilité' : 'Sa civilité'}
          validate={requiredValidator}
        >
          <RadioButtonGroup.RadioButton value={Civility.Homme}>
            <Typography.Text>M.</Typography.Text>
          </RadioButtonGroup.RadioButton>
          <RadioButtonGroup.RadioButton value={Civility.Femme}>
            <Typography.Text>Mme.</Typography.Text>
          </RadioButtonGroup.RadioButton>
        </FormField>

        <DateField
          name={`${driverType}Driver.birthDate`}
          className={styles.Field}
          label={isPrimaryDriver ? 'Votre date de naissance' : 'Sa date de naissance'}
          validate={birthDateValidator}
        />
      </Row>
      {isPrimaryDriver ? (
        <>
          <Row>
            <FormField
              autoComplete="off"
              component={InputText}
              name="primaryDriver.birthCity"
              label="Votre ville de naissance"
              validate={requiredValidator}
            />
            <BirthCountryField
              label="Votre pays de naissance"
              autoComplete="none"
              name="primaryDriver.birthCountryCode"
              validate={requiredValidator}
            />
          </Row>
          <Row>
            <ProfessionField validate={requiredValidator} />
            <MaritalStatusField validate={requiredValidator} />
          </Row>
          <Row>
            <FormField
              component={InputText}
              label="Votre adresse e-mail"
              validate={emailValidator}
              type="email"
              name="subscriberEmail"
            />

            <FormField
              component={PhoneInput}
              name="subscriberPhone"
              label="Votre numéro de mobile"
              validate={phoneNumberValidator}
            />
          </Row>
          <Row>
            <AddressAutocompleteFormField
              name="subscriberAutoCompletedAddress"
              initialFormattedAddress={initialFormattedAddress}
              label="Votre adresse"
            />
          </Row>
          <Row>
            <FormField name="hasAcceptedCommercialCommunication" type="checkbox" component={Checkbox}>
              <Typography.Text variant="regular" base="body-s">
                Je souhaite être informé.e des offres et avantages exclusifs du groupe Ornikar.
              </Typography.Text>
            </FormField>
          </Row>
          <Row>
            <FormField name="hasAcceptedPartnersCommunication" type="checkbox" component={Checkbox}>
              <Typography.Text variant="regular" base="body-s">
                Je souhaite recevoir les bons plans des{' '}
                <TypographyLink
                  variant="bold"
                  href="https://www.ornikar.com/a-propos/nos-partenaires-education-et-assurance"
                  hrefAttrs={{ target: '__blank' }}
                  onPress={(e) => e.stopPropagation()}
                >
                  partenaires sélectionnés
                </TypographyLink>{' '}
                par Ornikar.
              </Typography.Text>
            </FormField>
          </Row>
          <VStack marginTop="kitt.3">
            <Typography.Paragraph color="black-light" base="body-xsmall">
              Ornikar peut vous contacter par téléphone et d’autres moyens de communication au sujet de votre devis pour
              vous accompagner dans votre souscription. Vos données personnelles sont utilisées par Ornikar Assurances à
              des fins d’appréciation du risque, de proposition de devis, de passation, gestion et exécution des
              contrats d’assurance, prospection commerciale du groupe Ornikar et lutte contre la fraude. Pour en savoir
              plus et connaître vos droits RGPD, consultez notre{' '}
              <TypographyLink
                variant="bold"
                hrefAttrs={{ target: '__blank' }}
                href="https://www.ornikar.com/a-propos/charte-de-confidentialite"
                onPress={(e) => e.stopPropagation()}
              >
                politique de confidentialité
              </TypographyLink>
              .
            </Typography.Paragraph>
          </VStack>
        </>
      ) : null}
      <EmailErrorModal visible={modalVisible} email={values.subscriberEmail} onClose={() => setModalVisible(false)} />
    </>
  );
}
