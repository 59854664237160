import { Button } from '@ornikar/kitt-universal';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useModelValidator } from '../../../forms/validation/sections/vehicle/model';
import { VehiculePreSelectionType } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { submitButtonTestId } from '../../../utils/submitButtons';
import { ModeleAutocomplete } from './ModeleAutocomplete';

export function VehicleModelScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const modelValidator = useModelValidator();
  const { context } = useSubscriptionFsmState();

  const isFirstSection = useMemo(
    () => context.answers.vehiculePreSelectionType === VehiculePreSelectionType.BRAND,
    [context.answers.vehiculePreSelectionType],
  );

  return (
    <ScreenTemplateWithoutForm
      title={<>{isFirstSection ? <>C&apos; est parti&nbsp; ! </> : null}De quel modèle s’agit-il&nbsp;?</>}
      highlightTitle="Où trouver le modèle ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère D.3</b> de votre <b>carte grise (certificat d’immatriculation).</b>
        </div>
      }
      submitButtonComponent={
        context.answers?.vehiculeModele ? (
          <Button
            type="primary"
            testID={submitButtonTestId}
            onPress={() => {
              send({
                type: Event.ANSWER,
                answers: {
                  vehiculeModele: context.answers.vehiculeModele,
                  availableModels: context.availableModels,
                  availableCarburants: context.availableCarburants,
                  availablePuissanceFiscales: context.availablePuissanceFiscales,
                },
              });
            }}
          >
            Continuer
          </Button>
        ) : null
      }
    >
      <ModeleAutocomplete name="vehiculeModele" validate={modelValidator} />
    </ScreenTemplateWithoutForm>
  );
}
