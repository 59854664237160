import { Button } from '@ornikar/kitt-universal';
import { addMonths, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '../../../components/Dropdown';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { sendEvent as sendMixpanelEvent } from '../../../utils/mixpanel';
import { capitalize } from '../../../utils/string';
import { submitButtonTestId } from '../../../utils/submitButtons';
import { wait } from '../../../utils/wait';

interface Value {
  label: string;
  value: number;
}
const choices: Value[] = [];

for (let i = 0; i < 12; i++) {
  const date = addMonths(new Date(), i);
  const label = capitalize(format(date, 'MMMM', { locale: fr }));
  const value = date.getMonth() + 1;

  choices.push({ label, value });
}

export function VehicleCurrentContractAnniversaryMonthScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const userDoesnotKnow = (): void => {
    sendMixpanelEvent('Skip Anniversary Contract Question');
    send({
      type: Event.ANSWER,
      answers: {
        vehicleCurrentContractAnniversaryMonth: undefined,
      },
    });
  };

  const onChange = async (value: number): Promise<void> => {
    await wait();
    send({
      type: Event.ANSWER,
      answers: {
        vehicleCurrentContractAnniversaryMonth: value,
      },
    });
  };

  return (
    <ScreenTemplateWithoutForm
      title="Quand votre contrat arrive-t-il à échéance&nbsp;?"
      highlightTitle="Comment trouver cette information&nbsp;?"
      highlightContent="Vous trouverez la date d’échéance sur votre contrat d’assurance."
      submitButtonComponent={
        context.answers.vehicleCurrentContractAnniversaryMonth === undefined ? (
          <Button type="primary" testID={submitButtonTestId} onPress={userDoesnotKnow}>
            Je ne connais pas cette date
          </Button>
        ) : (
          <Button
            type="primary"
            testID={submitButtonTestId}
            onPress={() => {
              send({
                type: Event.ANSWER,
                answers: {
                  vehicleCurrentContractAnniversaryMonth: context.answers.vehicleCurrentContractAnniversaryMonth,
                },
              });
            }}
          >
            Continuer
          </Button>
        )
      }
    >
      <Dropdown
        testID="dropdown.vehicleCurrentContractAnniversaryMonthScreen.month"
        values={choices}
        value={context?.answers.vehicleCurrentContractAnniversaryMonth}
        placeholder={
          <FormattedMessage
            id="vehicleCurrentContractAnniversaryMonthScreen.placeholder"
            defaultMessage="Sélectionner le mois d'échéance"
          />
        }
        onChange={onChange}
      />
    </ScreenTemplateWithoutForm>
  );
}
