import { Button, CardModal, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AssureDepuisType } from '../../apis/types/LongQuoteAnswers';
import { useSubscriptionFsmDispatch } from '../../fsm/context';
import { Event } from '../../fsm/types';
import { wait } from '../../utils/wait';

interface LoiHamonModalProps {
  visible: boolean;
  onClose: () => void;
}

export function LoiHamonModal({ visible, onClose }: LoiHamonModalProps): ReactNode {
  const send = useSubscriptionFsmDispatch();

  return (
    <CardModal.ModalBehaviour visible={visible} onClose={onClose}>
      <CardModal>
        <CardModal.Header>
          <Typography.Header5 base="header5" variant="bold">
            <FormattedMessage id="loiHamonModal.title" defaultMessage="Votre contrat actuel a moins d'un an" />
          </Typography.Header5>
        </CardModal.Header>
        <CardModal.Body>
          <Typography.Paragraph>
            <FormattedMessage
              id="loiHamonModal.body"
              defaultMessage={`Nous pouvons vous afficher votre tarif sur-mesure, mais il se peut que nous ne puissions pas vous assurer avant que votre contrat actuel ne fête ses 1 an.
              \nLe premier anniversaire de votre contrat approche ? Nous pouvons vous accompagner pour finaliser votre souscription. Terminez votre devis et contactez-nous après l’obtention de votre tarif.`}
            />
          </Typography.Paragraph>
        </CardModal.Body>
        <CardModal.Footer>
          <View justifyContent="flex-end">
            <Button
              type="primary"
              onPress={async () => {
                // UX helper to feel natural
                await wait(100);

                send({ type: Event.ANSWER, answers: { vehiculeAssureDepuis: AssureDepuisType.Moins12Mois } });
              }}
            >
              <FormattedMessage id="loiHamonModal.button" defaultMessage="J'ai compris" />
            </Button>
          </View>
        </CardModal.Footer>
      </CardModal>
    </CardModal.ModalBehaviour>
  );
}
