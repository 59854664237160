import { startOfToday, subYears } from 'date-fns';
import { type ReactNode, useMemo } from 'react';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { formatDate } from '../../../utils/date';
import { wait } from '../../../utils/wait';

const field = 'vehiculeMiseEnCirculation';

export function VehicleDefaultCirculationDateScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const choices = useMemo(() => {
    const options = [
      {
        label: 'Plus de 10 ans',
        yearsToSub: 15,
      },
      {
        label: 'Entre 5 ans et 10 ans',
        yearsToSub: 10,
      },
      {
        label: 'Entre 1 an et 5 ans',
        yearsToSub: 5,
      },
      {
        label: 'Moins d’1 an',
        yearsToSub: 1,
      },
    ];

    return options.map(({ label, yearsToSub }) => ({
      label,
      value: formatDate(subYears(startOfToday(), yearsToSub)),
    }));
  }, []);

  const handleChange = async (value?: string): Promise<void> => {
    if (!value) return;

    // UX tricks
    await wait();

    send(Event.DEFAULT_ANSWER, {
      answers: {
        vehiculeMiseEnCirculation: value,
      },
    });
  };

  return (
    <RadioScreenTemplateWithoutForm
      id={field}
      fieldName={field}
      title="Votre véhicule a..."
      choices={choices}
      defaultValue={answers?.vehiculeMiseEnCirculation}
      onChange={handleChange}
      onPressContinue={() => {
        send(Event.DEFAULT_ANSWER, {
          answers: {
            vehiculeMiseEnCirculation: answers?.vehiculeMiseEnCirculation,
          },
        });
      }}
    />
  );
}
