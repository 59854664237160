import { Button } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { submitButtonTestId } from '../../../utils/submitButtons';
import { PuissanceFiscaleField } from './PuissanceFiscaleField';

export function VehiclePuissanceFiscaleScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  return (
    <ScreenTemplateWithoutForm
      title="Quelle est sa puissance fiscale&nbsp;?"
      highlightTitle="Où trouver la puissance fiscale ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère P.6</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      submitButtonComponent={
        context.answers?.vehiculePuissanceFiscale ? (
          <Button
            type="primary"
            testID={submitButtonTestId}
            onPress={() => {
              send({
                type: Event.ANSWER,
                answers: { vehiculePuissanceFiscale: context.answers?.vehiculePuissanceFiscale },
              });
            }}
          >
            Continuer
          </Button>
        ) : null
      }
    >
      <PuissanceFiscaleField />
    </ScreenTemplateWithoutForm>
  );
}
