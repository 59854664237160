import * as Sentry from '@sentry/react';
import { api } from './api';
import type { OrnikarPackageLevel, PaymentPeriodicity } from './types/Formula';

export type PromoCodeError = 'NOT_FOUND' | 'EXPIRED' | 'NOT_ELIGIBLE';

export interface PromoCodeBaseBody {
  submissionId: string;
  couponCode: string;
}

export interface PromoCodeCheckEligibilityBody extends PromoCodeBaseBody {
  paymentFrequency: PaymentPeriodicity | undefined;
  formula: OrnikarPackageLevel | undefined;
}

export interface PromoCodeSetSessionBody extends PromoCodeBaseBody {}

export interface PromoCodeCloseSessionBody {
  submissionId: string;
}

export interface PromoCodeCheckEligibilityResponse {
  isEligible: boolean;
  campaignStartDate?: string;
  campaignEndDate?: string;
  amount?: number;
  customCouponLabel?: string;
  minimumPrimeAmount?: number;
  rejectedReason?: string;
  generalConditionsURL?: string;
}

export const promoCodeCheckEligibility = async (
  body: PromoCodeCheckEligibilityBody,
): Promise<PromoCodeCheckEligibilityResponse> => {
  return api('api/v1/promo-code/check-eligibility', {
    method: 'POST',
    json: {
      ...body,
      origin: 'online',
    },
  });
};

export const promoCodeSetSession = async (body: PromoCodeSetSessionBody): Promise<void> => {
  await api('api/v1/promo-code/set-session', {
    method: 'POST',
    json: {
      ...body,
      origin: 'online',
    },
  });
};

export const promoCodeCloseSession = async (body: PromoCodeCloseSessionBody): Promise<void> => {
  try {
    await api('api/v1/promo-code/close-session', {
      method: 'POST',
      json: body,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
