import * as Sentry from '@sentry/react';
import type { Context } from '../fsm/types';
import { api } from './api';
import { mapServerRequestToLongQuoteAnswers } from './mappers/longQuoteAnswersMapper';
import type { OrnikarPackageLevel } from './types/Formula';
import type { APIDate, ServerLongQuoteRetrieve } from './types/LongQuoteAnswers';
import type { TarificationResult, TarificationResultOK } from './types/Quotation';
import { getVersionDetails } from './vehicules';

export const getRemoteQuoteFromPolicyCenter = async (
  submissionId: string,
  submissionNumber: string,
): Promise<Partial<Context>> => {
  try {
    const response = await api<{
      packages: Partial<TarificationResult>;
      quote: ServerLongQuoteRetrieve;
      selectedPackageName: OrnikarPackageLevel;
      minPackageEffectiveDate: APIDate;
    }>(`api/v2/quotes/${submissionId}?number=${submissionNumber}`);

    const { packages, quote, selectedPackageName, minPackageEffectiveDate } = response;

    const versionDetails = await getVersionDetails(quote.codeAuto);

    const answers = mapServerRequestToLongQuoteAnswers({
      ...quote,
      ...versionDetails,
    });

    return {
      answers,
      tarificationResult: { isOk: true, ...packages } as TarificationResultOK,
      selectedPackageName,
      minPackageEffectiveDate,
    };
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
};
