import type {
  DrivingFsmAnswers,
  InformationFsmAnswers,
  InsuranceFsmAnswers,
  QuoteFsmAnswers,
  VehicleFsmAnswers,
} from '../../../fsm/answers';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useSendContinue } from '../../../hooks/useSendContinue';
import type { DrivingFormValues, InformationFormValues, InsuranceFormValues, VehicleFormValues } from '../../answers';
import type { Submissions } from '../types';

type InsuranceSubmissionFormValues = VehicleFormValues &
  DrivingFormValues &
  InformationFormValues &
  InsuranceFormValues;

type InsuranceSubmissionFsmAnswers = VehicleFsmAnswers &
  DrivingFsmAnswers &
  InformationFsmAnswers &
  QuoteFsmAnswers &
  InsuranceFsmAnswers;

export const useInsuranceSubmissions: () => Submissions<
  InsuranceSubmissionFormValues,
  InsuranceSubmissionFsmAnswers
> = () => {
  const sendContinue = useSendContinue();
  const send = useSubscriptionFsmDispatch();

  return {
    HAMON_LAW_INFORMATION: ({ hamonWant, hamonAutoCompletedAddress, hamonName, hamonContractRef }) => {
      send(Event.ANSWER, {
        answers: {
          hamonWant,
          hamonContractRef,
          hamonName,
          hamonCompleteAdress: hamonAutoCompletedAddress?.formattedAddress,
          hamonAutoCompletedAddress,
          hamonAdress: hamonAutoCompletedAddress?.street,
          hamonCodePostal: hamonAutoCompletedAddress?.zipCode,
          hamonCommune: hamonAutoCompletedAddress?.city,
        },
      });
    },
    VEHICLE_LEGAL_INFORMATION: ({ kilometrageCompteur, vehiculeImmatriculation }) => {
      send(Event.ANSWER, {
        answers: {
          kilometrageCompteur,
          vehiculeImmatriculation,
        },
      });
    },
    PAYMENT_PLAN: () => {
      sendContinue();
    },
    LRE_INFORMATION: () => {
      sendContinue();
    },
    REGISTER_IBAN: ({ ibanNumber, ibanTitulaire }) => {
      send(Event.ANSWER, {
        answers: {
          ibanNumber,
          ibanTitulaire,
        },
      });
    },
    CONFIRM_SEPA_MANDATE: () => {
      sendContinue();
    },
    CREDIT_CARD_PAYMENT: () => {
      sendContinue();
    },
  };
};
