import { type ReactNode, useState } from 'react';
import { TitulaireCarteGriseType } from '../../../apis/types/LongQuoteAnswers';
import { RegistrationDocumentPartnerLink } from '../../../components/RegistrationDocumentPartner';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import {
  RegistrationDocumentErrorMessage,
  expectingCompanyOwner,
} from '../../../forms/validation/sections/driving/registrationDocumentOwner';
import { RegistrationDocumentOwnerIsSubscriberType, makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { isRegistrationDocumentPartnerLinkDisplayed } from '../../../fsm/guards/flagship';
import { Event } from '../../../fsm/types';
import { isBeforeToday } from '../../../fsm/validators';
import { wait } from '../../../utils/wait';

const registrationDocumentIsOnlyUserNameChoices = makeChoices(RegistrationDocumentOwnerIsSubscriberType, [
  [RegistrationDocumentOwnerIsSubscriberType.Oui, 'Oui'],
  [RegistrationDocumentOwnerIsSubscriberType.Non, 'Non'],
]);

export function DrivingRegistrationDocumentOwnerIsSubscriberScreen(): ReactNode {
  const [hasError, setHasError] = useState(false);

  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const isVehicleInPossession = answers.vehiculeAcquisition && isBeforeToday(answers.vehiculeAcquisition);

  const handleChange = async (value?: RegistrationDocumentOwnerIsSubscriberType): Promise<void> => {
    if (!value) return undefined;

    // validation : LLD and LOA funding method aren't compatible with being
    // the sole owner of the car. (car should be under company name)
    if (
      expectingCompanyOwner(answers, TitulaireCarteGriseType.Souscripteur) &&
      value === RegistrationDocumentOwnerIsSubscriberType.Oui
    ) {
      setHasError(true);

      return undefined;
    }

    setHasError(false);

    // UX tricks
    await wait();
    send({ type: Event.ANSWER, answers: { registrationDocumentOwnerIsSubscriber: value } });

    return undefined;
  };

  return (
    <RadioScreenTemplateWithoutForm
      title={
        <>
          Dernière question avant de passer à l’étape concernant vos informations. La carte grise (certificat
          d’immatriculation) du véhicule{' '}
          <span style={{ whiteSpace: 'nowrap' }}>{isVehicleInPossession ? 'est' : 'sera-t'}-elle</span> uniquement à
          votre <span style={{ whiteSpace: 'nowrap' }}>nom&nbsp;?</span>
        </>
      }
      fieldName="registrationDocumentOwnerIsSubscriber"
      choices={registrationDocumentIsOnlyUserNameChoices}
      highlightTitle="Qui est le propriétaire du véhicule ?"
      highlightContent={
        <>
          <div>
            C’est la personne qui est inscrite sur la carte grise (certificat d’immatriculation) sur les champs{' '}
            <b>C.1</b> et <b>C.4.1</b>.
          </div>
          {isRegistrationDocumentPartnerLinkDisplayed() ? (
            <>
              <br />
              <RegistrationDocumentPartnerLink />
            </>
          ) : null}
        </>
      }
      defaultValue={answers.registrationDocumentOwnerIsSubscriber}
      errorMessage={hasError ? <RegistrationDocumentErrorMessage /> : undefined}
      id="registrationDocumentOwnerIsSubscriber"
      onChange={handleChange}
      onPressContinue={() => {
        send({
          type: Event.ANSWER,
          answers: { registrationDocumentOwnerIsSubscriber: answers.registrationDocumentOwnerIsSubscriber },
        });
      }}
    />
  );
}
