import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { FinancementType, TitulaireCarteGriseType } from '../../../../apis/types/LongQuoteAnswers';
import type { LongQuoteAnswers } from '../../../../fsm/answers';

export const COMPANY_FUNDING_TYPES = [FinancementType.LLD, FinancementType.LOA];

export const REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING: TitulaireCarteGriseType[] = [
  TitulaireCarteGriseType.SocieteLeasing,
  // "Autre" is not a required document owner type for company funding
  // but this answer leads to an end-flow screen and thus it needs to bypass validation
  TitulaireCarteGriseType.Autre,
];

export function expectingCompanyOwner(answers: Partial<LongQuoteAnswers>, value: TitulaireCarteGriseType): boolean {
  return !!(
    answers.vehiculeFinancement &&
    COMPANY_FUNDING_TYPES.includes(answers.vehiculeFinancement) &&
    REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.length > 0 &&
    !REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.includes(value)
  );
}

export function RegistrationDocumentErrorMessage(): ReactNode {
  return (
    <FormattedMessage
      id="validators.registrationDocumentOwner.isNotFundingCompatible"
      defaultMessage="Parce que vous avez acheté votre véhicule en Location avec Option d’Achat ou Location Longue Durée, le propriétaire du véhicule ne peut être qu’une société de crédit"
    />
  );
}
