import { View } from '@ornikar/kitt-universal';
import { Form } from '@ornikar/react-forms-universal';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { ExitDoorLink } from '../../../components/exitDoor/ExitDoorLink';
import { useVehiculeMiseEnCirculationValidator } from '../../../forms/validation/sections/vehicle/vehiculeMiseEnCirculation';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { VehicleCirculationSubmitButton } from './VehiculeCirculationSubmitButton';

const fieldName = 'vehiculeMiseEnCirculation';

export function VehicleCirculationDateScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const vehiculeMiseEnCirculationValidator = useVehiculeMiseEnCirculationValidator(
    context.answers.vehiculeAcquisition!,
  );

  const handleNoCirculationDate = (): void => {
    send(Event.DEFAULT_ANSWER);
  };

  const onSubmit = async (values: Record<string, string>): Promise<void> => {
    const { vehiculeMiseEnCirculation } = values;

    send({ type: Event.ANSWER, answers: { vehiculeMiseEnCirculation } });
  };

  return (
    <Form
      initialValues={{ vehiculeMiseEnCirculation: context.answers?.vehiculeMiseEnCirculation }}
      fieldNames={[fieldName]}
      onSubmit={onSubmit}
    >
      <ScreenTemplateWithoutForm
        title="Quelle est sa date de mise en circulation&nbsp;?"
        highlightTitle="Où trouver la date de mise en circulation ?"
        highlightContent={
          <div>
            Rendez-vous au <b>repère B</b> de votre <b>carte grise (certificat d’immatriculation)</b> juste à côté du
            numéro d&apos;immatriculation du véhicule.
          </div>
        }
        submitButtonComponent={<VehicleCirculationSubmitButton />}
      >
        <DateField name={fieldName} validate={vehiculeMiseEnCirculationValidator} />
        <View marginTop="kitt.4" />
        <ExitDoorLink onPress={handleNoCirculationDate}>Je ne connais pas la date de mise en circulation.</ExitDoorLink>
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
