import * as Sentry from '@sentry/react';
import { format, max } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { setEffectiveDate } from '../apis/setEffectiveDate';
import { HamonWantType } from '../apis/types/LongQuoteAnswers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../fsm/context';
import type { Event } from '../fsm/types';
import { getHamonDate } from '../utils/getHamonDate';

export const useHamonEffectiveDate = (onSuccess: Event, onError: Event): string => {
  const {
    context: { answers, tarificationResult },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const { hamonWant, dateEffetSouhaite } = answers;

  const currentDate = new Date();

  const formattedDateEffetSouhaite = useMemo(() => new Date(dateEffetSouhaite ?? ''), [dateEffetSouhaite]);

  let effectiveDate = max([formattedDateEffetSouhaite, currentDate]);

  if (hamonWant === HamonWantType.Yes) {
    effectiveDate = max([getHamonDate(currentDate), effectiveDate]);
  }

  useEffect(() => {
    const updateEffectiveDate = async (newEffectiveDate: string) => {
      try {
        await setEffectiveDate(tarificationResult?.quoteId ?? '', newEffectiveDate);
        send(onSuccess, { answers: { dateEffetSouhaite: newEffectiveDate } });
      } catch (error) {
        Sentry.captureException(error);
        send(onError, error as Error);
      }
    };

    const isDateDifferent = format(formattedDateEffetSouhaite, 'yyyy-MM-dd') !== format(effectiveDate, 'yyyy-MM-dd');

    if (hamonWant === HamonWantType.Yes && isDateDifferent) {
      updateEffectiveDate(format(effectiveDate, 'yyyy-MM-dd'));
    }
  }, [hamonWant, formattedDateEffetSouhaite, effectiveDate, onSuccess, onError, send, tarificationResult?.quoteId]);

  return format(effectiveDate, 'yyyy-MM-dd');
};
