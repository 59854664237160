import { VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { Dropdown } from '../../../components/Dropdown';
import { ExitDoorLink } from '../../../components/exitDoor/ExitDoorLink';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

export function PuissanceFiscaleField(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const choices = useMemo(() => {
    return context.availablePuissanceFiscales.sort((a, b) => a - b).map((v) => ({ label: `${v}CV`, value: v }));
  }, [context.availablePuissanceFiscales]);

  const setVehiculePuissanceFiscaleDefaultValue = (): void => {
    send(Event.DEFAULT_ANSWER, {
      answers: {
        vehiculePuissanceFiscale: choices[0].value,
      },
    });
  };

  const onChange = async (value?: number): Promise<void> => {
    // UX tricks
    await wait();

    send({ type: Event.ANSWER, answers: { vehiculePuissanceFiscale: value } });
  };

  return (
    <VStack space="kitt.4" marginBottom={{ base: 'kitt.8', large: 0 }} zIndex={1}>
      <div data-testid="div.vehiclePuissanceFiscaleScreen.PuissanceFiscaleField.fiscalPower">
        <Dropdown
          values={choices}
          placeholder="Sélectionner"
          name="vehiculePuissanceFiscale"
          value={context.answers?.vehiculePuissanceFiscale}
          onChange={onChange}
        />
      </div>

      <ExitDoorLink onPress={setVehiculePuissanceFiscaleDefaultValue}>
        Je ne connais pas la puissance fiscale du véhicule.
      </ExitDoorLink>
    </VStack>
  );
}
