import { Button, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import drivingIllustration from './assets/driving-illustration.svg';

const alt = "Conductrice au volant d'une voiture";

export function CannotRetrieveQuoteScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();

  return (
    <ScreenTemplateWithoutForm hideBreadcrumbs disablePaddings hideSaveQuoteButton>
      <VStack alignItems="center">
        <View marginY="kitt.8">
          <img src={drivingIllustration} alt={alt} />
        </View>
        <VStack alignItems="center" space="kitt.4">
          <Typography.Header4 base="header4">
            <FormattedMessage id="expiredQuoteModal.title" defaultMessage="Votre devis n’est plus valide..." />
          </Typography.Header4>

          <Typography.Text base="body" textAlign="center">
            <FormattedMessage
              id="expiredQuoteModal.text"
              defaultMessage="Le tarif que nous vous proposions n’est plus d’actualité. N’hésitez pas à faire un autre devis pour connaître votre nouveau tarif !"
            />
          </Typography.Text>
        </VStack>
        <VStack marginTop="kitt.8" space="kitt.4" alignItems="center">
          <Button stretch type="primary" onPress={() => send(Event.CONTINUE)}>
            <FormattedMessage id="expiredQuoteModal.button" defaultMessage="Je fais un nouveau devis" />
          </Button>
        </VStack>
      </VStack>
    </ScreenTemplateWithoutForm>
  );
}
