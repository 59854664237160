import { Checkbox, Typography } from '@ornikar/kitt-universal';
import { useRequiredCheckValidator } from '@ornikar/react-validators';
import { type ReactNode } from 'react';
import { Field } from '../../../../components/Field';

export function IsSubscriberPrimaryCheckbox(): ReactNode {
  const validator = useRequiredCheckValidator();

  return (
    <Field name="isSubscriberPrimary" validate={validator} type="checkbox" component={Checkbox}>
      <Typography.Text>
        Je certifie que le souscripteur du contrat à résilier est la même personne que le conducteur principal de mon
        nouveau contrat Ornikar.
      </Typography.Text>
    </Field>
  );
}
