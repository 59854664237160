import { Light } from '@ornikar/illustrated-icons';
import { HStack, Icon, Typography, View } from '@ornikar/kitt-universal';
import type { AddonInfoModalContentItemProps } from '../../../../components/AddonInfoModal/components/AddonInfoModalContentItem';

export const infoModalContent: AddonInfoModalContentItemProps[] = [
  {
    title: (
      <Typography.Paragraph base="body" variant="bold">
        Quand vous...
      </Typography.Paragraph>
    ),
    description: (
      <>
        <Typography.Paragraph>
          ... Prêtez votre véhicule à un proche de confiance de temps en temps, soit un “conducteur occasionnel” qui ne
          figure pas au contrat, et qu’il cause un accident, deux franchises supplémentaires peuvent s’appliquer :{' '}
        </Typography.Paragraph>

        <ul>
          <li>
            <Typography.Paragraph>
              une franchise de 750 euros pour prêt de volant à une personne non déclarée au contrat;
            </Typography.Paragraph>
          </li>

          <li>
            <Typography.Paragraph>
              une franchise additionnelle de 750 euros si le conducteur à qui vous prêtez votre véhicule est considéré
              comme novice.
            </Typography.Paragraph>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: (
      <Typography.Paragraph base="body" variant="bold">
        Nous supprimons...
      </Typography.Paragraph>
    ),
    description: (
      <>
        <Typography.Paragraph>
          ...La franchise additionnelle "prêt de volant" à un conducteur ne figurant pas au contrat, soit une somme de
          750 euros demeurant à votre charge en cas de sinistre. Vous évitez donc de cumuler les deux franchises.
          <br />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text variant="bold">Attention,</Typography.Text> si vous prêtez votre véhicule à un conducteur
          considéré comme novice, la franchise de supplémentaire de 750 euros restera toujours à votre charge
        </Typography.Paragraph>
      </>
    ),
  },
  {
    title: (
      <HStack alignItems="center" space="kitt.2">
        <Icon icon={<Light />} color="kitt.palettes.lateOcean.lateOceanLight3" size="kitt.10" />
        <Typography.Paragraph base="body" variant="bold">
          Notre conseil
        </Typography.Paragraph>
      </HStack>
    ),
    description: (
      <Typography.Paragraph>
        Si vous prêtez votre voiture à quelqu'un alors que votre contrat d’assurance ne le permet pas, cette garantie
        vous sera vraiment utile pour rester tranquille en cas de pépin.
      </Typography.Paragraph>
    ),
    isHighlighted: true,
  },
  {
    title: (
      <Typography.Paragraph base="body" variant="bold">
        Quelle différence avec le conducteur secondaire ?
      </Typography.Paragraph>
    ),
    description: (
      <Typography.Paragraph>
        On différencie le conducteur occasionnel du conducteur secondaire, qui lui, a un usage habituel du véhicule.
      </Typography.Paragraph>
    ),
  },
  {
    title: (
      <Typography.Paragraph base="body" variant="bold">
        Exemple
      </Typography.Paragraph>
    ),
    description: (
      <View>
        <Typography.Paragraph>
          Un ami avec plus de 3 ans de permis a emprunté votre voiture et a endommagé le pare-choc d'une autre voiture
          lors d'une manœuvre sur un parking. <br />
          Vous serez dispensé de :
          <li style={{ marginLeft: '24px' }}>
            la franchise "prêt de volant" supplémentaire de 750€, concernant les remboursements des frais issus de
            l'accident.
          </li>
        </Typography.Paragraph>
      </View>
    ),
    isHighlighted: true,
    variant: 'dark',
  },
];
