import { InputText, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { listModelsWithPossibleValues } from '../../../apis/vehicules';
import type { FieldProps } from '../../../components/Field';
import { FieldV2 } from '../../../components/FieldV2';
import { LocalAutoCompleteInput } from '../../../components/LocalAutocompleteInput';
import type { VehicleFormValues } from '../../../forms/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useAsync } from '../../../hooks/useAsync';
import { serviceUnavailable } from '../../../utils/errorMessages';
import { sendNoOptionsFoundEvent } from '../../../utils/mixpanel';
import { wait } from '../../../utils/wait';

interface ModeleAutocompleteProps extends Pick<FieldProps<string>, 'label' | 'name' | 'validate'> {
  disabled?: boolean;
}

export function ModeleAutocomplete(props: ModeleAutocompleteProps): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const [models, modelsError, modelsLoading] = useAsync(() => {
    if (!props.disabled && answers.vehiculeMarque) {
      return listModelsWithPossibleValues(answers.vehiculeMarque);
    }

    return Promise.resolve([]);
  }, [answers.vehiculeMarque, props.disabled]);

  const modelsChoices = useMemo(() => {
    return models?.map((model) => ({ label: model.name, value: model.name })) || [];
  }, [models]);

  const vehicleModel = answers.vehiculeModele;

  useEffect(() => {
    if (!modelsLoading && (!models || models.length === 0)) {
      sendNoOptionsFoundEvent(props.name as keyof VehicleFormValues);
    }
  }, [models, modelsLoading, props.name]);

  if (modelsError) {
    return (
      <>
        <FieldV2 {...props} disabled component={InputText} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  if (props.disabled) {
    return <FieldV2 {...props} component={InputText} />;
  }

  const handleChange = async (value: string): Promise<void> => {
    if (value !== vehicleModel) {
      const pickedModel = models?.find((model) => model.name === value);

      // UX tricks
      await wait();

      send({
        type: Event.ANSWER,
        answers: {
          vehiculeMarque: answers.vehiculeMarque,
          vehiculeModele: value,
          availableCarburants: pickedModel?.energies ?? [],
          availablePuissanceFiscales: pickedModel?.puissances_fiscales ?? [],
        },
      });
    }
  };

  return (
    <LocalAutoCompleteInput
      name=""
      choices={modelsChoices}
      testID="autocomplete.ModeleAutocomplete.vehicleModel"
      isLoading={modelsLoading}
      placeholder={modelsLoading ? 'Chargement...' : ''}
      value={answers?.vehiculeModele ?? ''}
      onChange={handleChange}
      onBlur={() => {
        // noop
      }}
    />
  );
}
