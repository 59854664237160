import { Button } from '@ornikar/kitt-universal';
import { useRequiredValidator } from '@ornikar/react-validators';
import { type ReactNode, useMemo } from 'react';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { submitButtonTestId } from '../../../utils/submitButtons';
import { VersionAutoComplete } from './VersionAutocomplete';

export function VehicleVersionScreen(): ReactNode {
  const validator = useRequiredValidator();

  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showSubmitButton = useMemo(() => !!context?.answers?.vehiculeVersion, []);

  return (
    <ScreenTemplateWithoutForm
      title="Quelle est sa version&nbsp;?"
      highlightTitle="Où trouver la version ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère D2</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      submitButtonComponent={
        showSubmitButton ? (
          <Button
            type="primary"
            testID={submitButtonTestId}
            disabled={!context?.answers?.vehiculeVersion}
            onPress={() => {
              send(Event.DEFAULT_ANSWER, {
                answers: {
                  vehiculeVersion: context?.answers?.vehiculeVersion,
                  vehiculeIsUtility: context?.answers?.vehiculeIsUtility,
                },
              });
            }}
          >
            Continuer
          </Button>
        ) : null
      }
    >
      <VersionAutoComplete name="vehiculeVersion" validate={validator} />
    </ScreenTemplateWithoutForm>
  );
}
