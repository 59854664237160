import { Form } from '@ornikar/react-forms-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { DrivingCityScreenContent } from './DrivingCityScreenContent';
import { DrivingCityScreenSubmitButton } from './DrivingCityScreenSubmitButton';

export function DrivingCityScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const { context } = useSubscriptionFsmState();

  const onSubmit = (values: Record<string, string>): void => {
    const { parkingCodePostal, parkingCommune } = values;

    send({ type: Event.ANSWER, answers: { parkingCodePostal, parkingCommune } });
  };

  return (
    <Form
      fieldNames={['parkingCommune']}
      initialValues={{
        parkingCommune: context.answers?.parkingCommune,
        parkingCodePostal: context.answers?.parkingCodePostal,
      }}
      onSubmit={onSubmit}
    >
      <ScreenTemplateWithoutForm
        title={<FormattedMessage id="drivingCityScreen.title" defaultMessage="Dans quelle ville&nbsp;?" />}
        highlightTitle={<FormattedMessage id="drivingCityScreen.content" defaultMessage="Notre conseil d'assureur" />}
        highlightContent={
          <FormattedMessage
            id="drivingCityScreen.highlightContent"
            defaultMessage={
              'Certaines régions sont plus touchées par le vol de voiture.\nPour bien vous protéger contre le vol de votre véhicule, la garantie "Vol" est fortement recommandée.'
            }
          />
        }
        submitButtonComponent={<DrivingCityScreenSubmitButton />}
      >
        <DrivingCityScreenContent />
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
