import { saveJSONLocalStorage } from '../../../utils/safelocalstorage';
import type { StateMachineConfig } from '../../types';
import { STATE_MACHINE_CONFIG_KEY } from '../../types';
import { NO_INSURANCE_HISTORY } from '../preFilled/NO_INSURANCE_HISTORY';
import { PRIMARY_HAS_BEEN_COVERED } from '../preFilled/PRIMARY_HAS_BEEN_COVERED';
import { STANDARD } from '../preFilled/STANDARD';
import { SUCCESSFUL_QUOTE } from '../preFilled/SUCCESSFUL_QUOTE';
import { YOUNG_DRIVER } from '../preFilled/YOUNG_DRIVER';
import { brandSearch } from '../targetStep/brandSearchScreen';
import { licensePlateSearch } from '../targetStep/licensePlateScreen';
import type { StateMachineInitContext } from '../types';

const preFilledMapping: Record<string, Pick<StateMachineConfig, 'id' | 'context' | 'stateValue'>> = {
  // risk profiles
  NO_INSURANCE_HISTORY,
  STANDARD,
  YOUNG_DRIVER,
  // flow navigation
  SUCCESSFUL_QUOTE,
  PRIMARY_HAS_BEEN_COVERED,
};

const targetStepMapping: Record<string, Pick<StateMachineConfig, 'id' | 'context' | 'stateValue'>> = {
  brandSearch,
  licensePlateSearch,
};

export const overrideStateMachineConfig = async (
  context: StateMachineInitContext,
): Promise<Partial<StateMachineInitContext>> => {
  const searchParams = new URLSearchParams(window.location.search);
  const targetStep = searchParams.get('target') ?? '';
  const targetStepData = targetStepMapping[targetStep];

  if (targetStep) {
    saveJSONLocalStorage(STATE_MACHINE_CONFIG_KEY, {
      context: targetStepData.context,
      stateValue: targetStepData.stateValue,
    });

    return context;
  }

  if (import.meta.env.MODE === 'production') {
    return context;
  }

  const preFilledKey = searchParams.get('preFilled') || '';

  if (!preFilledKey || !preFilledMapping[preFilledKey]) {
    return context;
  }

  const preFilled = preFilledMapping[preFilledKey];

  saveJSONLocalStorage(STATE_MACHINE_CONFIG_KEY, {
    context: preFilled.context,
    stateValue: preFilled.stateValue,
  });

  return context;
};
