import { View, createChoicesComponent } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { GestureResponderEvent } from 'react-native';
import type { SetOptional } from 'type-fest';
import { RadioBlock } from './RadioBlock';

export interface ChoiceWithRender<T = string> {
  render: (checked: boolean) => ReactNode;
  value: T;
  subText?: string;
}
export interface ChoiceWithLabel<T = string> {
  label: ReactNode;
  value: T;
  subText?: string;
}

export type Choices<T> = ChoiceWithLabel<T> | ChoiceWithRender<T>;

export interface RadioBlockGroupProps<T = string> {
  id: string;
  value?: T;
  choices: Choices<T>[];
  onChange?: (value?: T) => void;
  onFocus?: (e?: unknown | GestureResponderEvent) => void;
  onBlur?: (e?: unknown | GestureResponderEvent) => void;
}

export function RadioBlockGroup<T = string>({
  id,
  value,
  onBlur,
  onFocus,
  onChange,
  choices,
}: RadioBlockGroupProps<T>): ReactNode {
  const RadioBlockChoices = createChoicesComponent<T, 'radio'>();

  return (
    <View display="flex">
      <RadioBlockChoices
        type="radio"
        direction="column"
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      >
        {choices.map((choice): ReactNode => {
          return (
            <RadioBlockChoices.Item
              key={choice.value as string}
              value={choice.value}
              selected={choice.value === value}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange}
            >
              <div data-testid={`${id}-${choice.value as unknown as string}`}>
                <RadioBlock checked={value === choice.value} subText={choice.subText}>
                  {(choice as SetOptional<ChoiceWithRender<T>, 'render'>).render
                    ? (choice as ChoiceWithRender<T>).render(value === choice.value)
                    : (choice as ChoiceWithLabel<T>).label}
                </RadioBlock>
              </div>
            </RadioBlockChoices.Item>
          );
        })}
      </RadioBlockChoices>
    </View>
  );
}

RadioBlockGroup.RadioBlock = RadioBlock;
