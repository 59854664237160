import { parse } from 'date-fns';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import type { FinancementType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { vehicleFundingOptionsList } from '../../../constants/mappers/vehicle';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

export function VehicleFundingMethodScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const isVehicleInPossession = useMemo(
    () => parse(answers.vehiculeAcquisition!, 'yyyy-MM-dd', new Date()) < new Date(),
    [answers.vehiculeAcquisition],
  );

  const handleChange = async (fundingMethod?: FinancementType): Promise<void> => {
    await wait();
    send({ type: Event.ANSWER, answers: { vehiculeFinancement: fundingMethod } });
  };

  return (
    <RadioScreenTemplateWithoutForm
      defaultValue={answers.vehiculeFinancement}
      title={
        <div>
          Merci, dernière question sur votre véhicule. Comment{' '}
          {isVehicleInPossession ? "l'avez-vous financé" : 'allez-vous le financer'}
          &nbsp;?
        </div>
      }
      fieldName="vehiculeFinancement"
      choices={vehicleFundingOptionsList}
      highlightTitle="Précision importante"
      highlightContent="Cette information est vérifiée lors de l'envoi des pièces justificatives obligatoires demandées par votre assureur."
      id="fundingMethodScreen"
      onChange={handleChange}
      onPressContinue={() => {
        send({ type: Event.ANSWER, answers: { vehiculeFinancement: answers.vehiculeFinancement } });
      }}
    />
  );
}
