import type { ReactNode } from 'react';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { isPropertyOwnerOptionsList } from '../../../constants/mappers/driving';
import type { YesNoQuestion } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

export function DrivingIsPropertyOwnerScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const handleChange = async (value?: YesNoQuestion): Promise<void> => {
    await wait();

    send({ type: Event.ANSWER, answers: { isPropertyOwner: value } });
  };

  return (
    <RadioScreenTemplateWithoutForm
      fieldName="isPropertyOwner"
      title="Êtes-vous propriétaire de votre logement&nbsp;?"
      choices={isPropertyOwnerOptionsList}
      id="isPropertyOwner"
      defaultValue={answers.isPropertyOwner}
      onChange={handleChange}
      onPressContinue={() => {
        send({ type: Event.ANSWER, answers: { isPropertyOwner: answers.isPropertyOwner } });
      }}
    />
  );
}
