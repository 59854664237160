import { Button } from '@ornikar/kitt-universal';
import { type ReactNode, useState } from 'react';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { VehiculePreSelectionType } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { submitButtonTestId } from '../../../utils/submitButtons';
import { wait } from '../../../utils/wait';
import Car from './assets/car.svg';
import Immatriculation from './assets/immatriculation.svg';
import { ChoiceButton } from './components/ChoiceButton';
import styles from './styles.module.css';

const choices = [
  {
    illustration: <img src={Immatriculation} alt="Illustration d'une plaque d'immatriculation" />,
    title: 'Rechercher par plaque',
    datatestid: 'button.VehicleSearchChoiceScreen.searchCarByPlate',
    subtitle: "C'est plus rapide !",
    value: VehiculePreSelectionType.LICENSE_PLATE,
  },
  {
    illustration: <img src={Car} alt="Illustration d'une voiture" />,
    title: 'Rechercher par marque',
    datatestid: 'button.VehicleSearchChoiceScreen.searchCarByBrand',
    value: VehiculePreSelectionType.BRAND,
  },
];

export function VehicleSearchChoiceScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const [selection, setSelection] = useState<VehiculePreSelectionType | undefined>(
    context.answers?.vehiculePreSelectionType,
  );

  async function handleSelect(value: VehiculePreSelectionType): Promise<void> {
    setSelection(value);

    // makes the auto-submit feels natural
    await wait();

    send({
      type: Event.ANSWER,
      answers: {
        vehiculePreSelectionType: value,
      },
    });
  }

  const title = context.answers.primaryDriver?.firstName
    ? `Bienvenue ${context.answers.primaryDriver.firstName}\u00A0! Commençons par trouver votre véhicule.`
    : 'Bienvenue\u00A0! Commençons par trouver votre véhicule.';

  return (
    <ScreenTemplateWithoutForm
      title={title}
      submitButtonComponent={
        context.answers.vehiculePreSelectionType === undefined ? null : (
          <Button
            testID={submitButtonTestId}
            type="primary"
            onPress={() => {
              send({
                type: Event.ANSWER,
                answers: {
                  vehiculePreSelectionType: selection,
                },
              });
            }}
          >
            Continuer
          </Button>
        )
      }
    >
      <div className={styles.Choices}>
        {choices.map(({ value, ...rest }) => (
          <ChoiceButton {...rest} key={value} isSelected={selection === value} onSelect={() => handleSelect(value)} />
        ))}
      </div>
    </ScreenTemplateWithoutForm>
  );
}
