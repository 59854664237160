import * as Sentry from '@sentry/react';
import { getRemoteQuoteFromPolicyCenter } from '../../../apis/getRemoteQuoteFromPolicyCenter';
import { listVersions } from '../../../apis/vehicules';
import { safeGetJSONFromLocalStorage } from '../../../utils/safelocalstorage';
import { clearUrlSearchParam } from '../../../utils/urlSearchParam';
import { initializeContext } from '../../context';
import type { StateMachineConfig } from '../../types';
import { STATE_MACHINE_CONFIG_KEY, State } from '../../types';
import { sanitizeLocaleStateMachine, sanitizeRemoteQuote } from '../../validators';
import type { StateMachineInitContext } from '../types';

const shouldReset = (context: StateMachineInitContext): boolean => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('reset') === 'true') {
    clearUrlSearchParam('reset');

    return true;
  }

  if (context.agentEmail) {
    return true;
  }

  return false;
};

export const getLocaleMachineConfigService = async (
  context: StateMachineInitContext,
): Promise<Partial<StateMachineInitContext>> => {
  const localeMachineConfig = safeGetJSONFromLocalStorage<StateMachineConfig>(STATE_MACHINE_CONFIG_KEY);

  if (!localeMachineConfig || shouldReset(context)) {
    return {
      ...context,
      config: {
        ...context.config,
      },
    };
  }

  if (localeMachineConfig.context.tarificationResult?.isOk) {
    const { submissionId, submissionNumber } = localeMachineConfig.context.tarificationResult;
    // const [, formattedSubmissionId] = submissionId.split('pc:');
    let formattedSubmissionId = '';
    try {
      [, formattedSubmissionId] = submissionId.split('pc:');
    } catch (error) {
      Sentry.captureException(error, {
        extra: { localeMachineConfig },
      });
      throw error;
    }

    const { answers, tarificationResult, selectedPackageName, minPackageEffectiveDate } =
      await getRemoteQuoteFromPolicyCenter(formattedSubmissionId, submissionNumber);

    if (answers && tarificationResult) {
      const { quoteAnswers, reachedSummaryScreenSections } = sanitizeRemoteQuote(answers);

      const availableVersions = await listVersions(
        answers.vehiculeMarque,
        answers.vehiculeModele,
        answers.vehiculePuissanceFiscale,
        answers.vehiculeCarburant,
      );

      return {
        config: {
          id: 'remote-machine',
          context: initializeContext({
            answers: quoteAnswers,
            availableVersions,
            tarificationResult,
            reachedSummaryScreenSections,
            selectedPackageName,
            minPackageEffectiveDate,
          }),
          stateValue: State.FORMULES,
        },
      };
    }
  }

  const config = await sanitizeLocaleStateMachine(localeMachineConfig);

  return {
    ...context,
    config,
  };
};
