import { Button, CardModal, HStack, Typography, View } from '@ornikar/kitt-universal';
import { type ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_VIEWED, RECALCULATION_CTA_CLICK } from '../../constants/eventName';
import { useSubscriptionFsmDispatch } from '../../fsm/context';
import { Event } from '../../fsm/types';
import { sendEvent as sendMixpanelEvent } from '../../utils/mixpanel';
import { wait } from '../../utils/wait';
import { CarIllustration } from './CarIllustration';

interface PriceNeedsRecalculationModalProps {
  visible: boolean;
  onClose: () => void;
}

export function PriceNeedsRecalculationModal({ visible, onClose }: PriceNeedsRecalculationModalProps): ReactNode {
  const send = useSubscriptionFsmDispatch();

  useEffect(() => {
    sendMixpanelEvent(MODAL_VIEWED, {
      modal_name: 'INS - Quote needs recalculation',
    });
  }, []);

  return (
    <CardModal.ModalBehaviour visible={visible} onClose={onClose}>
      <CardModal>
        <CardModal.Body>
          <HStack justifyContent="center" marginTop="kitt.15">
            <CarIllustration />
          </HStack>
          <View marginTop="kitt.8">
            <Typography.Header5 base="header5" variant="bold" textAlign="center">
              <FormattedMessage
                id="priceNeedsRecalculationModal.title"
                defaultMessage="Votre tarif doit être recalculé"
              />
            </Typography.Header5>
          </View>
          <View marginTop="kitt.4">
            <Typography.Paragraph textAlign="center">
              <FormattedMessage
                id="priceNeedsRecalculationModal.body"
                defaultMessage="Vous avez sélectionné une formule différente de celle de votre devis initial. Pour continuer, nous devons recalculer le prix de votre assurance. Celui-ci peut être différent du tarif actuellement affiché"
              />
            </Typography.Paragraph>
          </View>
          <HStack justifyContent="center" marginTop="kitt.8" marginBottom="kitt.4">
            <Button
              type="primary"
              onPress={async () => {
                await wait(100);

                sendMixpanelEvent(RECALCULATION_CTA_CLICK, {
                  cta_action: 'Recalculate quote because effective date in the past',
                  cta_name: 'QuoteRecalculation',
                  cta_text: 'Recalculer le tarif',
                });

                send(Event.RECALCULATE_PRICE);
              }}
            >
              <FormattedMessage id="priceNeedsRecalculationModal.button" defaultMessage="Recalculer le tarif" />
            </Button>
          </HStack>
        </CardModal.Body>
      </CardModal>
    </CardModal.ModalBehaviour>
  );
}
