import { type ReactNode, useMemo } from 'react';
import { LicenseType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';
import { wait } from '../../../utils/wait';
import europeanFlag from './assets/europeanFlag.svg';
import frenchFlag from './assets/frenchFlag.svg';
import styles from './styles.module.css';

const choices = makeChoices(LicenseType, [
  [
    LicenseType.french,
    <div className={styles.Choice}>
      En France <img src={frenchFlag} alt="drapeau français" />
    </div>,
  ],
  [
    LicenseType.european,
    <div className={styles.Choice}>
      Dans un autre pays d'Europe <img src={europeanFlag} alt="drapeau européen" />
    </div>,
  ],
  [LicenseType.other, 'Autre'],
]);

export interface LicenseTypeScreenProps {
  driver: Driver;
}

export function LicenseTypeScreen({ driver }: LicenseTypeScreenProps): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const isLicenseDateInFuture = useIsLicenseDateInFuture(driver);
  const isPrimaryDriver = useMemo(() => driver === Driver.Primary, [driver]);
  const isSecondaryDriver = useMemo(() => driver === Driver.Secondary, [driver]);

  const title = `Où ${isPrimaryDriver ? 'votre' : 'son'} permis de conduire ${isPrimaryDriver ? 'vous' : 'lui'} ${
    isLicenseDateInFuture ? 'sera-t-il' : 'a-t-il été'
  } délivré\u00A0?`;

  const handleChange = async (licenseType?: LicenseType): Promise<void> => {
    // Ui tricks
    await wait();

    if (isPrimaryDriver) {
      send({
        type: Event.ANSWER,
        answers: {
          primaryDriver: { ...answers.primaryDriver, licenseType },
        },
      });
    } else if (isSecondaryDriver) {
      send({ type: Event.ANSWER, answers: { secondaryDriver: { ...answers.secondaryDriver, licenseType } } });
    }
  };

  return (
    <RadioScreenTemplateWithoutForm
      defaultValue={isPrimaryDriver ? answers.primaryDriver?.licenseType : answers.secondaryDriver?.licenseType}
      fieldName={`${driver}Driver.licenseType`}
      choices={choices}
      title={title}
      highlightTitle="Quels sont les autres pays d’Europe&nbsp;?"
      highlightContent={
        <ul>
          <li>Les pays de l’Union Européenne,</li>
          <li>Les pays de l’Espace Economique Européen : Islande, Liechtenstein et Norvège,</li>
          <li>Le Royaume-Uni, si le permis a été délivré avant le 1er janvier 2021.</li>
        </ul>
      }
      id="licenseType"
      onChange={handleChange}
      onPressContinue={() => {
        if (isPrimaryDriver) {
          send({
            type: Event.ANSWER,
            answers: {
              primaryDriver: answers.primaryDriver,
            },
          });
        } else if (isSecondaryDriver) {
          send({ type: Event.ANSWER, answers: { secondaryDriver: answers.secondaryDriver } });
        }
      }}
    />
  );
}
