import { InputText } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import type { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { Field } from '../../../components/Field';
import { Row } from '../../../components/Row';
import { useZipcodeValidator } from '../../../forms/validation/sections/informations/zipcode';
import { CommunesField } from './CommunesField';
import styles from './styles.module.css';

export function DrivingCityScreenContent(): ReactNode {
  const { change, blur } = useForm();
  const zipcodeValidator = useZipcodeValidator();

  return (
    <Row>
      <Field
        autoFocus
        className={styles.Field}
        component={InputText}
        name="parkingCodePostal"
        label={<FormattedMessage id="drivingCityScreen.zipcode" defaultMessage="Code postal" />}
        validate={zipcodeValidator}
        onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
          const { value } = e.target as unknown as { value: string };

          change('parkingCommune', undefined);
          if (value.length === 5) {
            // trigger validation for communesField to get communes from api
            setTimeout(() => blur('parkingCodePostal'), 0);
          }
        }}
      />
      <CommunesField />
    </Row>
  );
}
