import { Form } from '@ornikar/react-forms-universal';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useVehicleAcquisitionDateValidator } from '../../../forms/validation/sections/vehicle/vehicleDateAcquisition';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { VehicleAcquisitionDateSubmitButton } from './VehicleAcquisitionDateSubmitButton';

const fieldName = 'vehiculeAcquisition';

export function VehicleAcquisitionDateScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();

  const vehicleAcquisitionDateValidator = useVehicleAcquisitionDateValidator(
    context.answers.vehiculeMiseEnCirculation!,
  );

  const send = useSubscriptionFsmDispatch();

  const handleSubmit = (values: Record<string, unknown>): void => {
    const { vehiculeAcquisition } = values;

    send(Event.ANSWER, {
      answers: {
        vehiculeAcquisition,
      },
    });
  };

  return (
    <Form
      fieldNames={[fieldName]}
      initialValues={{ vehiculeAcquisition: context?.answers?.vehiculeAcquisition }}
      onSubmit={handleSubmit}
    >
      <ScreenTemplateWithoutForm
        title="À quelle date allez-vous acheter votre véhicule&nbsp;? Si le véhicule est déjà en votre possession, indiquez la date d'achat."
        highlightTitle="Où trouver la date d'achat ?"
        highlightContent={
          <ul>
            <li>
              Pour un véhicule neuf, rendez-vous au <u>repère B</u> de votre carte grise (certificat d’immatriculation).
            </li>
            <li>
              Pour une voiture d&apos;occasion, rendez-vous au <u>repère I</u> de votre carte grise (certificat
              d’immatriculation).
            </li>
          </ul>
        }
        submitButtonComponent={<VehicleAcquisitionDateSubmitButton />}
      >
        <DateField name={fieldName} validate={vehicleAcquisitionDateValidator} />
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
