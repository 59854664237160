import { Typography, View } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessageWithRichTextFormatting } from '../../../../utils/intl';

const messages = defineMessages({
  hamon: {
    id: 'shoppingCart.hamon',
    defaultMessage:
      "Votre contrat démarre à cette date conformément à la loi Hamon. Vous restez couvert par votre assurance actuelle jusqu'à l'activation du nouveau contrat.{br}{br}<b>Vérifiez la date de premier anniversaire sur votre avis d’échéance</b> : si votre contrat a moins d’un an, demandez à être rappelé en 2 clics et finalisez avec un agent !",
  },
});

export function HamonHighlight(): ReactNode {
  return (
    <View
      backgroundColor="kitt.palettes.lateOcean.warmEmbraceLight1"
      paddingX={{ base: 'kitt.4', medium: 'kitt.6' }}
      paddingY="kitt.4"
      borderBottomRadius="kitt.5"
    >
      <Typography.Paragraph base="body-small">
        <FormattedMessageWithRichTextFormatting {...messages.hamon} />
      </Typography.Paragraph>
    </View>
  );
}
