import { useSubscriptionFsmState, useSubscriptionFsmStateValue } from '../../fsm/context';
import type { State } from '../../fsm/types';
import type { FormValues } from '../answers';
import { useInformationSubmissions } from './sections/information';
import { useInsuranceSubmissions } from './sections/insurance';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSubmission = () => {
  const fsmState = useSubscriptionFsmStateValue();
  const { context } = useSubscriptionFsmState();

  const informationSubmission = useInformationSubmissions();
  const insuranceSubmission = useInsuranceSubmissions();

  const submissions = {
    ...informationSubmission,
    ...insuranceSubmission,
  };

  const submit = async (formValues: Partial<FormValues>) => {
    const submission = submissions[fsmState as State];
    if (submission) {
      const res = await submission(formValues, context.answers);
      return res || undefined;
    }
    return undefined;
  };

  return submit;
};
