import { CaretLeftRegularIcon } from '@ornikar/kitt-icons/phosphor';
import type { ButtonProps } from '@ornikar/kitt-universal';
import { Button, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useGoBack } from '../../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { Breadcrumbs } from '../../Breadcrumbs';
import { ButtonSection } from '../../ButtonSection';
import { ConstrainedWidth } from '../../ConstrainedWidth';
import { InputHighlight } from '../../InputHighlight';

export type ValidationButtonProps = ButtonProps & {
  label?: ReactNode;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
};

export interface ScreenTemplateWithoutFormProps {
  title?: ReactNode;
  hideSaveQuoteButton?: boolean;
  hideBreadcrumbs?: boolean;

  /**
   * @optional
   * Using this property will overwrite the default ButtonSection's content
   * */
  submitButtonComponent?: ReactNode;
  highlightTitle?: ReactNode;
  highlightContent?: ReactNode;
  highlightExpandable?: boolean;
  maxWidth?: string;
  titleMaxWidth?: string;
  disablePaddings?: boolean;
  shouldDisplayBackButton?: boolean;
  children: NonNullable<ReactNode>;
  backButtonLabel?: ReactNode;
}

export function ScreenTemplateWithoutForm({
  title,
  hideSaveQuoteButton = false,
  hideBreadcrumbs = false,
  submitButtonComponent,
  highlightTitle,
  highlightContent,
  highlightExpandable,
  maxWidth,
  titleMaxWidth,
  disablePaddings,
  shouldDisplayBackButton,
  children,
  backButtonLabel = 'Étape précédente',
}: ScreenTemplateWithoutFormProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const [canBack, goBack] = useGoBack();

  return (
    <VStack alignItems="center" paddingBottom={disablePaddings ? 0 : 'kitt.8'} minHeight="calc(100vh - 60px)">
      <VStack alignItems="center" paddingTop="kitt.8" width="100%">
        {isDesktop && !hideBreadcrumbs ? (
          <View marginBottom="kitt.8">
            <Breadcrumbs />
          </View>
        ) : null}

        <ConstrainedWidth maxWidth={titleMaxWidth || maxWidth} padding={disablePaddings ? 0 : undefined}>
          {(canBack || shouldDisplayBackButton) && isDesktop ? (
            <View marginBottom={32} marginLeft={-22}>
              <Button icon={<CaretLeftRegularIcon />} type="subtle-dark" onPress={goBack}>
                {backButtonLabel}
              </Button>
            </View>
          ) : null}
          {title ? (
            <VStack width="100%" justifyContent="center" marginBottom={32}>
              <Typography.h4 variant="bold" textAlign="center">
                {title}
              </Typography.h4>
            </VStack>
          ) : null}
        </ConstrainedWidth>
      </VStack>
      <ConstrainedWidth maxWidth={maxWidth} padding={disablePaddings ? 0 : undefined}>
        {children}

        {highlightContent ? (
          <InputHighlight title={highlightTitle} expandable={highlightExpandable}>
            {highlightContent}
          </InputHighlight>
        ) : null}

        <ButtonSection
          submitButtonComponent={submitButtonComponent}
          hideSaveQuoteButton={hideSaveQuoteButton}
          disablePaddings={disablePaddings}
        />
      </ConstrainedWidth>
    </VStack>
  );
}
