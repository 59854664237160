import { View } from '@ornikar/kitt-universal';
import { Form } from '@ornikar/react-forms-universal';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { useLicenseDateValidator } from '../../../forms/validation/sections/driving/licenseDate';
import type { DriverAnswers } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';
import { DateField } from '../../DateField';
import { ScreenTemplateWithoutForm } from '../../ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { ExitDoorLink } from '../../exitDoor/ExitDoorLink';
import type { HighlightContentProps, PrimaryOrSecondaryScreenTemplateType } from '../types';
import { LicenseDateTemplateSubmitButton } from './LicenseDateTemplateSubmitButton';

function InfoBlockContent({ isPrimaryDriver, civility }: HighlightContentProps): ReactNode {
  return isPrimaryDriver ? (
    <div>
      Pour trouver la date d&apos;obtention de votre permis :
      <ul>
        <li>
          <b>Si vous avez le papier rose</b> : rendez-vous sur le volet du milieu, à la <b>mention B</b>,
        </li>
        <li>
          <b>Si vous avez un nouveau permis</b> : rendez-vous <b>au dos</b> de votre précieux document à la{' '}
          <b>colonne 10</b>.
        </li>
      </ul>
    </div>
  ) : (
    <div>
      Pour trouver la date d&apos;obtention du permis :
      <ul>
        <li>
          <b>
            {civility === Civility.Homme
              ? "S'il est détenteur du papier rose"
              : 'Si elle est détentrice du papier rose'}
          </b>{' '}
          : rendez-vous sur le volet du milieu, à la <b>mention B</b>,
        </li>
        <li>
          <b>{civility === Civility.Homme ? "S'il a" : 'Si elle a'} un nouveau permis</b> : rendez-vous <b>au dos</b> du
          précieux document à la <b>colonne 10</b>.
        </li>
      </ul>
    </div>
  );
}

export function LicenseDateTemplate({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const fieldName = `${driverType}Driver.licenseDate`;

  const isPrimaryDriver = useMemo(() => driverType === Driver.Primary, [driverType]);
  const isSecondaryDriver = useMemo(() => driverType === Driver.Secondary, [driverType]);

  const licenseDateValidator = useLicenseDateValidator(driverType);

  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const valueFromContext = isPrimaryDriver
    ? context?.answers?.primaryDriver?.licenseDate
    : context?.answers?.secondaryDriver?.licenseDate;

  const handleNoLicenseDate = (): void => {
    send(Event.DEFAULT_ANSWER);
  };

  const handleSubmit = async (values: Record<string, unknown>): Promise<void> => {
    const { primaryDriver, secondaryDriver } = values;

    await wait();

    if (isPrimaryDriver) {
      send({
        type: Event.ANSWER,
        answers: {
          primaryDriver: { ...context.answers?.primaryDriver, ...(primaryDriver as DriverAnswers) },
        },
      });
    } else if (isSecondaryDriver) {
      send({
        type: Event.ANSWER,
        answers: {
          secondaryDriver: { ...context.answers?.secondaryDriver, ...(secondaryDriver as DriverAnswers) },
        },
      });
    }
  };

  const civility = context.answers[`${driverType}Driver`]?.civility;
  const attribut = civility === Civility.Homme ? 'il' : 'elle';

  return (
    <Form fieldNames={[fieldName]} initialValues={{ fieldName: valueFromContext }} onSubmit={handleSubmit}>
      <ScreenTemplateWithoutForm
        title={
          isPrimaryDriver ? (
            <>Place aux questions sur votre conduite. À quelle date avez-vous eu votre permis&nbsp;?</>
          ) : (
            <>
              À quelle date {context.answers.secondaryDriver?.firstName} a-t-{attribut} eu son permis&nbsp;?
            </>
          )
        }
        highlightTitle="Où trouver la date ?"
        highlightContent={
          isPrimaryDriver ? <InfoBlockContent isPrimaryDriver /> : <InfoBlockContent civility={civility} />
        }
        submitButtonComponent={<LicenseDateTemplateSubmitButton />}
      >
        <DateField name={fieldName} validate={licenseDateValidator} defaultValue={valueFromContext} />

        {isPrimaryDriver ? (
          <View marginTop="kitt.4" marginBottom={{ base: 'kitt.8', large: 0 }}>
            <ExitDoorLink onPress={handleNoLicenseDate}>Je n’ai pas encore mon permis.</ExitDoorLink>
          </View>
        ) : null}
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
