import { Form } from '@ornikar/react-forms-universal';
import { useComposeValidators, useDateValidator, useRequiredValidator } from '@ornikar/react-validators';
import { startOfToday } from 'date-fns';
import type { ReactNode } from 'react';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { DateField } from '../../../components/DateField';
import { FieldV2 } from '../../../components/FieldV2';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { TooltipReleveInformation } from '../../../components/TooltipReleveInformation';
import { useDateBeforeTomorrowValidator } from '../../../forms/validation/sharedValidators';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { VehicleLastInsuranceDateSubmitButton } from './VehicleLastInsuranceDateSubmitButton';

const fieldName = 'vehicleLastInsuranceEndDate';

export function VehicleLastInsuranceEndDateScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const { context } = useSubscriptionFsmState();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const vehicleLastInsuranceEndDateValidator = useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    useDateBeforeTomorrowValidator(),
  );

  const onSubmit = (values: Record<string, string>) => {
    const { vehicleLastInsuranceEndDate } = values;

    send({ type: Event.ANSWER, answers: { vehicleLastInsuranceEndDate } });
  };

  return (
    <Form
      fieldNames={[fieldName]}
      initialValues={{ vehicleLastInsuranceEndDate: context.answers?.vehicleLastInsuranceEndDate }}
      onSubmit={onSubmit}
    >
      <ScreenTemplateWithoutForm
        title="Depuis quand votre véhicule n'est-il pas assuré&nbsp;?"
        highlightTitle="Précision importante"
        highlightContent={
          <div>
            Si le véhicule a déjà été assuré à votre nom: la date de fin d’assurance de votre véhicule se trouve sur
            votre Relevé d’Information <TooltipReleveInformation />.
            <br />
            <br />
            Si le véhicule n’a jamais été assuré à votre nom: entrez la date d’achat du véhicule.
          </div>
        }
        submitButtonComponent={<VehicleLastInsuranceDateSubmitButton />}
      >
        {isDesktop ? (
          <FieldV2
            name={fieldName}
            component={CustomDatePicker}
            maxDate={startOfToday()}
            validate={vehicleLastInsuranceEndDateValidator}
          />
        ) : (
          <DateField name={fieldName} validate={vehicleLastInsuranceEndDateValidator} />
        )}
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
