/**
 * From the object { a: {b: 1, c: 2 }, e: 3 } will return ['a.b', 'a.c', 'e'] whereas a classic Object.keys
 * would return ["a", "e"]
 * */
export function getComposedKeys(entry: Record<string, unknown>, prefix?: string): string[] {
  const keys: string[] = [];
  Object.keys(entry).forEach((key) => {
    const value = entry[key];

    if (value && typeof value === 'object' && Object.prototype.toString.call(entry) === '[object Object]') {
      keys.push(...getComposedKeys(value as Record<string, unknown>, key));
    } else {
      keys.push(`${prefix ?? ''}${prefix ? '.' : ''}${key}`);
    }
  });

  return keys;
}
