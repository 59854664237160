import type { ReactNode } from 'react';
import type { DejaAssureAvecNomType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { vehicleInsuredByOptionsList } from '../../../constants/mappers/vehicle';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

export function VehicleIsCoveredScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const handleSelect = async (value?: DejaAssureAvecNomType): Promise<void> => {
    await wait();
    send({
      type: Event.ANSWER,
      answers: {
        vehiculeDejaAssureAvecNom: value,
      },
    });
  };

  return (
    <RadioScreenTemplateWithoutForm<DejaAssureAvecNomType>
      id="vehicleDejaAssureAvecNom"
      title="Votre véhicule est-il actuellement assuré&nbsp;?"
      highlightTitle="Notre conseil"
      highlightContent={
        <div>
          Un véhicule n’ayant pas été assuré pendant plusieurs jours après sa date d’achat, peut ne pas être assurable
          ou bien faire augmenter votre tarif.
          <br />
          <br />
          Il est donc préférable d’assurer votre véhicule dès que vous l’avez en votre possession.
        </div>
      }
      fieldName="vehiculeDejaAssureAvecNom"
      choices={vehicleInsuredByOptionsList}
      defaultValue={context.answers?.vehiculeDejaAssureAvecNom}
      onChange={handleSelect}
      onPressContinue={() => {
        send({
          type: Event.ANSWER,
          answers: {
            vehiculeDejaAssureAvecNom: context.answers?.vehiculeDejaAssureAvecNom,
          },
        });
      }}
    />
  );
}
