import { Form } from '@ornikar/react-forms-universal';
import type { ReactNode } from 'react';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { DateField } from '../../../components/DateField';
import { FieldV2 } from '../../../components/FieldV2';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useVehiculeCoverageDateValidator } from '../../../forms/validation/sections/vehicle/vehiculeCoverageDateValidator';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { VehicleCoverageDateSubmitButton } from './VehicleCoverageDateSubmitButton';
import { useVehicleCoverageMaxDate } from './useVehicleCoverageMaxDate';
import { useVehicleCoverageMinDate } from './useVehicleCoverageMinDate';

const fieldName = 'dateEffetSouhaite';

export function VehicleCoverageDateScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const send = useSubscriptionFsmDispatch();

  const vehiculeCoverageDateValidator = useVehiculeCoverageDateValidator(context.answers.vehiculeAcquisition!);

  const minDate = useVehicleCoverageMinDate(context.answers.vehiculeAcquisition!);
  const maxDate = useVehicleCoverageMaxDate();

  const handleSubmit = (values: Record<string, unknown>): void => {
    const { dateEffetSouhaite } = values;

    send(Event.ANSWER, {
      answers: {
        dateEffetSouhaite,
      },
    });
  };

  return (
    <Form fieldNames={[fieldName]} onSubmit={handleSubmit}>
      <ScreenTemplateWithoutForm
        title="Quand souhaitez-vous assurer votre véhicule&nbsp;?"
        highlightTitle="Précision importante"
        highlightContent={
          <div>
            Assurer votre véhicule est obligatoire, y compris lorsque vous ne l&apos;utilisez pas.
            <br />
            <br />
            Dans certaines situations, votre responsabilité peut-être engagée que vous soyez ou non au volant !<br />
            <br />
            De plus, une interruption d&apos;assurance, même d&apos;un jour, peut s&apos;avérer bloquante pour vous
            fournir un tarif.
          </div>
        }
        submitButtonComponent={<VehicleCoverageDateSubmitButton />}
      >
        {isDesktop ? (
          <FieldV2
            name={fieldName}
            component={CustomDatePicker}
            minDate={minDate}
            maxDate={maxDate}
            validate={vehiculeCoverageDateValidator}
          />
        ) : (
          <DateField name={fieldName} validate={vehiculeCoverageDateValidator} minDate={minDate} maxDate={maxDate} />
        )}
      </ScreenTemplateWithoutForm>
    </Form>
  );
}
