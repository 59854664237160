import type { ReactNode } from 'react';

export function CarIllustration(): ReactNode {
  return (
    <svg width="150" height="100" viewBox="0 0 150 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M141 27.4902H135.48V96.5202H141V27.4902Z"
        fill="#DCB7FD"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M145.12 1H136.87C134.75 1 133.03 2.71922 133.03 4.84V39.12C133.03 41.2408 134.75 42.96 136.87 42.96H145.12C147.241 42.96 148.96 41.2408 148.96 39.12V4.84C148.96 2.71922 147.241 1 145.12 1Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M59.52 60.9304V29.1504H40.52C36.1487 29.1528 31.9075 30.6376 28.4892 33.3621C25.0708 36.0866 22.6774 39.8898 21.7 44.1504L17.75 60.9304"
        fill="white"
      />
      <path
        d="M59.52 60.9304V29.1504H40.52C36.1487 29.1528 31.9075 30.6376 28.4892 33.3621C25.0708 36.0866 22.6774 39.8898 21.7 44.1504L17.75 60.9304"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M111.202 88.8002C113.406 82.9615 111.192 76.7183 106.258 74.8557C101.323 72.9931 95.5363 76.2163 93.3324 82.055C91.1284 87.8938 93.342 94.1369 98.2765 95.9995C103.211 97.8621 108.998 94.6389 111.202 88.8002Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.54 60.8809H115.69C118.077 60.8809 120.366 61.8291 122.054 63.5169C123.742 65.2047 124.69 67.4939 124.69 69.8809V84.6609H67.54V60.8809Z"
        fill="#D6BAF9"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.7502 60.9297H11.6602C9.07603 60.9297 6.59759 61.9555 4.7694 63.7819C2.94121 65.6082 1.91281 68.0856 1.91016 70.6697V84.6697H79.8002V72.1397C79.8015 70.668 79.5127 69.2106 78.9505 67.8506C78.3882 66.4906 77.5634 65.2547 76.5233 64.2136C75.4831 63.1725 74.248 62.3467 72.8885 61.7832C71.529 61.2197 70.0718 60.9297 68.6002 60.9297H59.5202"
        fill="#D6BAF9"
      />
      <path
        d="M17.7502 60.9297H11.6602C9.07603 60.9297 6.59759 61.9555 4.7694 63.7819C2.94121 65.6082 1.91281 68.0856 1.91016 70.6697V84.6697H79.8002V72.1397C79.8015 70.668 79.5127 69.2106 78.9505 67.8506C78.3882 66.4906 77.5634 65.2547 76.5233 64.2136C75.4831 63.1725 74.248 62.3467 72.8885 61.7832C71.529 61.2197 70.0718 60.9297 68.6002 60.9297H59.5202"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.3199 60.9304H59.5199L53.3799 29.1504H72.7599C77.3301 29.1502 81.7799 30.6158 85.4554 33.332C89.1309 36.0481 91.8383 39.8715 93.1799 44.2404L98.3199 60.9304Z"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.2769 88.8099C71.4808 82.9712 69.2673 76.7281 64.3328 74.8655C59.3983 73.0028 53.6115 76.2261 51.4076 82.0648C49.2036 87.9035 51.4172 94.1467 56.3517 96.0093C61.2861 97.8719 67.073 94.6486 69.2769 88.8099Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5464 88.8002C31.7504 82.9615 29.5368 76.7183 24.6023 74.8557C19.6679 72.9931 13.881 76.2163 11.6771 82.055C9.47316 87.8937 11.6867 94.1369 16.6212 95.9995C21.5557 97.8621 27.3425 94.6389 29.5464 88.8002Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 96.5293H149" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M87.8604 68.8906V74.6406"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M116.99 68.8906V74.6406" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.75 60.8809H61.74" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M48.1201 67.5996H53.0701" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M59.5195 60.9297V74.5397" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M61.7396 29.1503L44.8096 21.8203"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M98.3199 60.9305L60.0499 60.6205L58.6699 56.4905L96.7899 56.2305L98.3199 60.9305Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.6 1H131.35C129.229 1 127.51 2.71922 127.51 4.84V39.12C127.51 41.2408 129.229 42.96 131.35 42.96H139.6C141.721 42.96 143.44 41.2408 143.44 39.12V4.84C143.44 2.71922 141.721 1 139.6 1Z"
        fill="#DCB7FD"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M135.48 38.3206C137.877 38.3206 139.82 36.3775 139.82 33.9806C139.82 31.5837 137.877 29.6406 135.48 29.6406C133.083 29.6406 131.14 31.5837 131.14 33.9806C131.14 36.3775 133.083 38.3206 135.48 38.3206Z"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M135.48 26.1605C137.877 26.1605 139.82 24.2174 139.82 21.8205C139.82 19.4236 137.877 17.4805 135.48 17.4805C133.083 17.4805 131.14 19.4236 131.14 21.8205C131.14 24.2174 133.083 26.1605 135.48 26.1605Z"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M135.48 13.9905C137.877 13.9905 139.82 12.0475 139.82 9.65055C139.82 7.25363 137.877 5.31055 135.48 5.31055C133.083 5.31055 131.14 7.25363 131.14 9.65055C131.14 12.0475 133.083 13.9905 135.48 13.9905Z"
        fill="#D44148"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
