import { HStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useDetectAndroidKeyboard } from '../../hooks/useDetectAndroidKeyboard';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { SaveQuoteButton } from '../SaveQuoteButton';
import type { ValidationButtonProps } from '../ScreenTemplates/ScreenTemplate';
import { SubmitButton } from '../SubmitButton';
import styles from './styles.module.css';

export interface ButtonSectionProps {
  isSubmitButtonVisible?: boolean;
  hideSaveQuoteButton?: boolean;
  disablePaddings?: boolean;
  isButtonLoading?: boolean;
  submitButton?: Partial<ValidationButtonProps>;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  submitButtonComponent?: ReactNode;
}

export function ButtonSection({
  isSubmitButtonVisible,
  hideSaveQuoteButton,
  disablePaddings,
  isButtonLoading,
  submitButton = {
    label: 'Continuer',
    disabled: false,
    loading: false,
  },
  submitButtonComponent,
  onSubmit,
}: ButtonSectionProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const isAndroidKeyboardOpen = useDetectAndroidKeyboard();
  if (!isAndroidKeyboardOpen && (isSubmitButtonVisible || !hideSaveQuoteButton)) {
    return (
      <div className={styles.ButtonSection} style={disablePaddings ? { margin: 0 } : undefined}>
        {submitButtonComponent || (
          <>
            {isSubmitButtonVisible ? (
              <SubmitButton disabled={submitButton?.disabled || isButtonLoading} isLoading={isButtonLoading}>
                {submitButton?.label || 'Continuer'}
              </SubmitButton>
            ) : null}
            {!isDesktop && !hideSaveQuoteButton ? (
              <HStack justifyContent="center">
                <SaveQuoteButton type="subtle" />
              </HStack>
            ) : null}
          </>
        )}
      </div>
    );
  }

  return null;
}
