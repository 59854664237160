import type { AssignAction, EventObject } from 'xstate';
import { assign } from 'xstate';
import { capitalize } from '../../utils/string';
import type {
  CancellationOrSuspensionRecord,
  ClaimRecord,
  InsuranceRecordType,
  TerminationRecord,
  WithdrawalType,
} from '../answers';
import { YesNoQuestion } from '../answers';
import type {
  AnswerEvent,
  Context,
  DeleteInsuranceRecordEvent,
  Driver,
  EditInsuranceRecordEvent,
  Event,
} from '../types';
import type { AnswerAssign, SimpleEventAssign, TypedAssign } from './types';
import { assignAnswer, resetAnswer } from './utils';

/* TERMINATION */
export const resetTermination: (driver: Driver) => AnswerAssign = (driver) =>
  assignAnswer(`${driver}Driver.terminationRecords`, []);

export const resetTerminationCountForNonPayment: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(`${driver}Driver.terminationRecords[0].terminationCount`);

export const resetTerminationSuspensionDuration: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(`${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.suspensionDuration`);

export const resetTerminationCancellationOrSuspensionRecord: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(`${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord`);

export const resetTerminationCancellationOrSuspensionTrafficControl: (driver: Driver) => AnswerAssign = (
  driver: Driver,
) =>
  resetAnswer([
    `${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.reason`,
    `${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.bloodAlcoholLevel`,
  ]);

export const resetTerminationCancellationOrSuspensionClaim: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(`${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord`);

export const resetTerminationCancellationOrSuspensionAlcoholLevel: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(`${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.bloodAlcoholLevel`);

export const resetTerminationCancellationOrSuspensionClaimAlcoholLevel: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(`${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.bloodAlcoholLevel`);

/* CANCELLATION OR SUSPENSION */

export const skipAllNextCancellationOrSuspensionRecords: (driver: Driver) => SimpleEventAssign<Event.SKIP> = (driver) =>
  assignAnswer(`${driver}Driver.cancellationOrSuspensionRecords`, (context: Context) => {
    const endIndex = context.editingInsuranceRecord.index + 1;
    return context.answers[`${driver}Driver`]!.cancellationOrSuspensionRecords!.slice(0, endIndex);
  });

export const resetCancellationsAndSuspensions: (driver: Driver) => SimpleEventAssign<Event.SKIP | Event.ANSWER> = (
  driver,
) =>
  assignAnswer(
    { path: `${driver}Driver.cancellationOrSuspensionRecords`, value: [] },
    { path: `${driver}Driver.hasBeenCancelledOrSuspended`, value: YesNoQuestion.NO },
  );

export const resetCancellationOrSuspensionTrafficControl: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(({ editingInsuranceRecord: { index } }) => [
    `${driver}Driver.cancellationOrSuspensionRecords[${index}].reason`,
    `${driver}Driver.cancellationOrSuspensionRecords[${index}].bloodAlcoholLevel`,
  ]);

export const resetCancellationOrSuspensionClaim: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.cancellationOrSuspensionRecords[${index}].claimRecord`,
  );

export const resetCancellationOrSuspensionAlcoholLevel: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(
    ({ editingInsuranceRecord: { index } }) =>
      `${driver}Driver.cancellationOrSuspensionRecords[${index}].bloodAlcoholLevel`,
  );

export const resetCancellationOrSuspensionClaimAlcoholLevel: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(
    ({ editingInsuranceRecord: { index } }) =>
      `${driver}Driver.cancellationOrSuspensionRecords[${index}].claimRecord.bloodAlcoholLevel`,
  );

/* CLAIM */

export const resetClaims: (driver: Driver) => AnswerAssign = (driver) =>
  assignAnswer(`${driver}Driver.claimRecords`, []);

export const resetClaimAccident: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(({ editingInsuranceRecord: { index } }) => [
    `${driver}Driver.claimRecords[${index}].driver`,
    `${driver}Driver.claimRecords[${index}].responsibilityLevel`,
    `${driver}Driver.claimRecords[${index}].underTheInfluenceOf`,
    `${driver}Driver.claimRecords[${index}].bloodAlcoholLevel`,
  ]);

export const resetClaimWithAlcoholAnswers: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(({ editingInsuranceRecord: { index } }) => [
    `${driver}Driver.claimRecords[${index}].underTheInfluenceOf`,
    `${driver}Driver.claimRecords[${index}].bloodAlcoholLevel`,
  ]);

export const resetClaimAlcoholLevel: (driver: Driver) => AnswerAssign = (driver) =>
  resetAnswer(({ editingInsuranceRecord: { index } }) => `${driver}Driver.claimRecords[${index}].bloodAlcoholLevel`);

/* EDITING INSURANCE RECORD */

/**
 * Increment the `editingInsuranceRecord` `index` if at least one record is declared.
 */
export const incrementEditingInsuranceRecordIfOneAlreadyDeclared: <E extends EventObject>(
  driver: Driver,
  record: InsuranceRecordType,
) => TypedAssign<E> = (driver, record) => {
  return assign((context) => {
    const editingRecordIndex = context.editingInsuranceRecord.index;
    const editingRecord = context.answers?.[`${driver}Driver`]?.[`${record}Records`]?.[editingRecordIndex];
    const isFirstRecordDefined = editingRecordIndex !== 0 || Boolean(editingRecord);

    return {
      editingInsuranceRecord: {
        type: record,
        driver,
        index: isFirstRecordDefined ? editingRecordIndex + 1 : editingRecordIndex,
      },
    };
  });
};

/**
 * Decrement current `editingInsuranceRecord` `index`.
 */
export const decrementEditingInsuranceRecord: (
  driver: Driver,
  record: InsuranceRecordType,
) => SimpleEventAssign<Event.BACK> = (driver, record) =>
  assign((context) => {
    return {
      editingInsuranceRecord: {
        type: record,
        driver,
        index: Math.max(0, context.editingInsuranceRecord.index - 1),
      },
    };
  });

/**
 * Increment current `editingInsuranceRecord` `index`.
 */
export const incrementEditingInsuranceRecord: (
  driver: Driver,
  record: InsuranceRecordType,
) => SimpleEventAssign<Event.ADD_CLAIM> = (driver, record) =>
  assign((context) => {
    return {
      editingInsuranceRecord: {
        type: record,
        driver,
        index: (context.editingInsuranceRecord.index || 0) + 1,
      },
    };
  });

/**
 * Set the `editingInsuranceRecord` `index` to the last record.
 */
export const updateToLastIndexEditingInsuranceRecord: (
  driver: Driver,
  recordType: InsuranceRecordType,
) => SimpleEventAssign<Event.BACK | Event.ADD_SUSPENSION | Event.ADD_CANCELLATION | Event.ADD_CLAIM | Event.ANSWER> = (
  driver,
  recordType,
) =>
  assign((context) => {
    const recordsLength = context.answers[`${driver}Driver`]?.[`${recordType}Records`]
      ? Number(context.answers[`${driver}Driver`]?.[`${recordType}Records`]?.length)
      : 0;
    const lastIndex = Math.max(0, recordsLength - 1);

    return {
      editingInsuranceRecord: {
        recordType,
        driver,
        index: lastIndex,
      },
    };
  });

/**
 * Set the `editingInsuranceRecord` `index` to 0.
 */
export const initializeEditingInsuranceRecord: (driver: Driver, recordType: InsuranceRecordType) => AnswerAssign = (
  driver,
  recordType,
) =>
  assign<Context, AnswerEvent>(() => ({
    editingInsuranceRecord: {
      recordType,
      driver,
      index: 0,
    },
  }));

/**
 * Update the `editingInsuranceRecord` with the `recordType` and `index` from the event.
 */
export const updateEditingInsuranceRecordFromEvent: (
  driver: Driver,
) => SimpleEventAssign<Event.EDIT_INSURANCE_RECORD> = (driver) =>
  assign((_, event) => {
    const { recordIndex: index, recordType } = event as any;
    return {
      editingInsuranceRecord: {
        recordType,
        driver,
        index,
      },
    };
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setHasReachedInsuranceRecordSummary = (driver: Driver) =>
  assign(() => {
    return {
      [`hasReached${capitalize(driver)}InsuranceRecordSummary`]: true,
    };
  });

const getRecordType = (event: DeleteInsuranceRecordEvent | EditInsuranceRecordEvent) =>
  event.recordType as InsuranceRecordType;

/**
 * Delete the record `recordType` at `index` from the event.
 */
export const deleteInsuranceRecordFromEvent: (driver: Driver) => AssignAction<Context, DeleteInsuranceRecordEvent> = (
  driver,
) =>
  assignAnswer(
    (_, event) => `${driver}Driver.${getRecordType(event as DeleteInsuranceRecordEvent)}Records`,
    (context: Context, event: DeleteInsuranceRecordEvent) => {
      const records = context.answers[`${driver}Driver`]?.[`${getRecordType(event)}Records`];

      if (!records) return undefined;

      // @dev the `[...records]` spread notation is due to typescript throwing an error to `records`.
      // We may want to investigate on that
      return [...records].filter((_: any, index: number) => index !== event.recordIndex) as
        | TerminationRecord[]
        | CancellationOrSuspensionRecord[]
        | ClaimRecord[];
    },
  );

export const setWithdrawalType: (
  driver: Driver,
  withdrawalType: WithdrawalType,
) => SimpleEventAssign<Event.ADD_SUSPENSION | Event.ADD_CANCELLATION> = (driver, withdrawalType) =>
  assignAnswer(
    (context) =>
      `${driver}Driver.cancellationOrSuspensionRecords[${context.editingInsuranceRecord.index}].withdrawalType`,
    withdrawalType,
  );
