import { type ReactNode, useState } from 'react';
import { AssureDepuisType } from '../../../apis/types/LongQuoteAnswers';
import { LoiHamonModal } from '../../../components/LoiHamonModal';
import { RadioScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/RadioScreenTemplateWithoutForm';
import { vehicleInsuredSinceOptionsList } from '../../../constants/mappers/vehicle';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { wait } from '../../../utils/wait';

export function VehicleCoveredSinceScreen(): ReactNode {
  const [modalVisible, setModalVisible] = useState(false);
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const handleSelect = async (value?: AssureDepuisType): Promise<void> => {
    if (value === AssureDepuisType.Moins12Mois) {
      setModalVisible(true);
    } else {
      await wait();

      send({ type: Event.ANSWER, answers: { vehiculeAssureDepuis: value } });
    }
  };

  return (
    <>
      <RadioScreenTemplateWithoutForm<AssureDepuisType>
        id="vehiculeAssureDepuis"
        title="Depuis quand êtes-vous chez votre assureur actuel&nbsp;?"
        fieldName="vehiculeAssureDepuis"
        choices={vehicleInsuredSinceOptionsList}
        highlightTitle="Nous résilions pour vous"
        defaultValue={context.answers?.vehiculeAssureDepuis}
        highlightContent={
          <div>
            <b>. Si votre contrat a plus d&apos;1 an</b> votre assurance actuelle sera résiliée par nos soins dès lors
            que vous souhaitez assurer le même véhicule.
            <br />
            En raison des démarches que nous mènerons auprès de votre assureur actuel, votre nouveau contrat prendra
            effet au bout d’un mois environ.
            <br />
            Mais pas de panique, vous restez assuré par votre ancien contrat pendant toute la durée des démarches.
            <br />
            <br />
            <b>. Si votre contrat a moins d&apos;1 an</b>, Nous pouvons vous afficher votre tarif sur-mesure, mais, dans
            certains cas, nous ne pourrons pas vous assurer avant que votre contrat actuel ne fête ses 1 an.
          </div>
        }
        onChange={handleSelect}
        onPressContinue={() => {
          send({
            type: Event.ANSWER,
            answers: {
              vehiculeAssureDepuis: context.answers?.vehiculeAssureDepuis,
            },
          });
        }}
      />
      <LoiHamonModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
}
